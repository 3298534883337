import { createSelector } from 'reselect';
import moment from 'moment';
import { selectACType } from '../functionalitiesSelector';
import {
    selectActiveConsumers,
    selectActiveProducers,
    selectActiveProsumers,
    selectIsOperationACI,
} from '../operationSelectors';
import {
    selectACCAutoProductionByProducers,
    selectACCGesKpi,
    selectACCGlobalConsumption,
    selectACCGlobalProduction,
    selectACCProductionByProducers,
    selectGlobalACCConsumptionChartData,
    selectGlobalACCProductionChartData,
} from './ACCMeasurementSelectors';
import {
    selectACIAutoProductionByProducers,
    selectACIGesKpi,
    selectACIGlobalConsumption,
    selectACIGlobalProduction,
    selectACIProductionByProducers,
    selectGlobalACIConsumptionChartData,
    selectGlobalACIDetailsConsumptionChartData,
    selectGlobalACIDetailsProductionChartData,
    selectGlobalACIProductionChartData,
} from './ACIMeasurementSelectors';
import {
    selectFilter,
    selectMeasurement,
    selectMetricsData,
    selectSelectedDates,
} from './sharedSelectors';
import { formatNumber } from '../../../../adapters/primary/libs/formatNumber';
import { selectConsumptionByConsumersMetricsData } from './metrics/consumptionByConsumersMetricsSelector';
import { selectAutoConsumptionByConsumersMetricsData } from './metrics/autoConsumptionByConsumersMetricsSelector';
import { selectAutoConsumptionByProducersMetrics } from './metrics/autoConsumptionByProducersMetricsSelector';
import { selectProductionByProducersMetricsData } from './metrics/productionByProducersMetricsSelector';

const selectStoreRange = createSelector(
    selectFilter,
    (periodFilter) => periodFilter.range
);

const selectUploadedDataRange = createSelector(
    selectStoreRange,
    (range) => range.uploadedData
);

const selectSgeTiersRange = createSelector(
    selectStoreRange,
    (range) => range.sgeTiersData
);

const selectRange = createSelector(
    selectUploadedDataRange,
    selectSgeTiersRange,
    (uploadedDataRange, sgeTiersRange) => ({
        from:
            uploadedDataRange.from && sgeTiersRange.from
                ? moment.min(uploadedDataRange.from, sgeTiersRange.from)
                : uploadedDataRange.from || sgeTiersRange.from,
        to:
            uploadedDataRange.to && sgeTiersRange.to
                ? moment.max(uploadedDataRange.to, sgeTiersRange.to)
                : uploadedDataRange.to || sgeTiersRange.to,
        uploadTo: uploadedDataRange.to,
    })
);

const selectRangeLoading = createSelector(
    selectFilter,
    (filter) => filter.loading
);

const selectSelectedRange = createSelector(
    selectSelectedDates,
    ({ from, to }) => {
        let selectedRange = 1;
        if (!from || !to) {
            return selectedRange;
        }
        const diff = to.diff(from, 'months', true);
        if (diff > 1 && diff <= 3) selectedRange = 3;
        else if (diff > 3 && diff <= 6) selectedRange = 6;
        else if (diff > 6 && diff <= 12) selectedRange = 12;
        return selectedRange;
    }
);

// global Production

const selectGlobalProduction = createSelector(
    selectACCGlobalProduction,
    selectACIGlobalProduction,
    selectIsOperationACI,
    selectACType,
    (ACCGlobalProduction, ACIGlobalProduction, isOperationACI, acType) =>
        !isOperationACI || acType === 'ACC'
            ? ACCGlobalProduction
            : ACIGlobalProduction
);

// global consumption

const selectGlobalConsumption = createSelector(
    selectACCGlobalConsumption,
    selectACIGlobalConsumption,
    selectIsOperationACI,
    selectACType,
    (ACCGlobalConsumption, ACIGlobalConsumption, isOperationACI, acType) =>
        !isOperationACI || acType === 'ACC'
            ? ACCGlobalConsumption
            : ACIGlobalConsumption
);

// production by producers
// TODO UNUSED?
const selectProductionByProducers = createSelector(
    selectACCProductionByProducers,
    selectACIProductionByProducers,
    selectIsOperationACI,
    selectACType,
    (
        ACCProductionByProducers,
        ACIProductionByProducers,
        isOperationACI,
        acType
    ) =>
        !isOperationACI || acType === 'ACC'
            ? ACCProductionByProducers
            : ACIProductionByProducers
);

// Excess by producers

const selectExcessByProducers = createSelector(selectMetricsData, (metrics) =>
    metrics && metrics.excessByProducer
        ? handleMetric(metrics.excessByProducer)
        : []
);

// consumption by consumers

const selectConsumptionByConsumers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.totalCons ? handleMetric(metrics.totalCons) : []
);

// autoProduction by producers

const selectAutoProductionByProducers = createSelector(
    selectACCAutoProductionByProducers,
    selectACIAutoProductionByProducers,
    selectIsOperationACI,
    selectACType,
    (
        ACCAutoProductionByProducers,
        ACIAutoProductionByProducers,
        isOperationACI,
        acType
    ) =>
        !isOperationACI || acType === 'ACC'
            ? ACCAutoProductionByProducers
            : ACIAutoProductionByProducers
);

// autoConsumption by consumers
// TODO UNUSED?
const selectAutoConsumptionByConsumers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.localCons ? handleMetric(metrics.localCons) : []
);

// autoConsumption rate

// GlobalConsumptionChartData

const selectGlobalConsumptionChartData = createSelector(
    selectGlobalACCConsumptionChartData,
    selectGlobalACIConsumptionChartData,
    selectGlobalACIDetailsConsumptionChartData,
    selectIsOperationACI,
    selectACType,
    (
        globalACCConsumptionChartData,
        globalACIConsumptionChartData,
        globalACIDetailsConsumptionChartData,
        isOperationACI,
        acType
    ) =>
        !isOperationACI || acType === 'ACC'
            ? globalACCConsumptionChartData
            : acType === 'AC collective + individuelle'
            ? globalACIConsumptionChartData
            : globalACIDetailsConsumptionChartData
);

// globalProductionChartData

const selectGlobalProductionChartData = createSelector(
    selectGlobalACCProductionChartData,
    selectGlobalACIProductionChartData,
    selectGlobalACIDetailsProductionChartData,
    selectIsOperationACI,
    selectACType,
    (
        globalACCProductionChartData,
        globalACIProductionChartData,
        globalACIDetailsProductionChartData,
        isOperationACI,
        acType
    ) =>
        !isOperationACI || acType === 'ACC'
            ? globalACCProductionChartData
            : acType === 'AC collective + individuelle'
            ? globalACIProductionChartData
            : globalACIDetailsProductionChartData
);

const selectGesKpi = createSelector(
    selectACCGesKpi,
    selectACIGesKpi,
    selectIsOperationACI,
    selectACType,
    (ACCGesKpi, ACIGesKpi, isOperationACI, acType) =>
        !isOperationACI || acType === 'ACC' ? ACCGesKpi : ACIGesKpi
);

const selectIsQueryingDataInRange = createSelector(
    selectMeasurement,
    (measurement) => measurement.isQueryingDataInRange
);

const selectDistribution = createSelector(
    selectMeasurement,
    (measurement) => measurement.distribution
);

const selectDistributionData = createSelector(
    selectDistribution,
    (distribution) => distribution.data || new Map()
);

const selectDistributionLoading = createSelector(
    selectDistribution,
    (distribution) => distribution.loading
);

// ENEDIS specific case
// TODO UNUSED?
const handleMetric = (metric) => {
    return metric.map(({ prm, padt, name, power, participantId }) => {
        return {
            prm,
            padt,
            id: participantId,
            name,
            value: power || 0,
        };
    });
};

export const selectConsumersMetrics = createSelector(
    selectActiveConsumers,
    selectConsumptionByConsumersMetricsData,
    selectAutoConsumptionByConsumersMetricsData,
    (consumers, consumptionMetrics, autoConsumptionMetrics) => {
        return consumers.map((consumer) => {
            const id = consumer.id;
            const dsoId = consumer.dsoId;
            const name = consumer.name || dsoId;
            const consumption =
                consumptionMetrics.find(
                    ({ participantId }) =>
                        participantId.toString() === id.toString()
                )?.value || 0;
            const autoConsumption =
                autoConsumptionMetrics.find(
                    ({ participantId }) =>
                        participantId.toString() === id.toString()
                )?.value || 0;
            const autoProduction = ` (${(
                (consumption > 0 ? autoConsumption / consumption : 0) * 100
            ).toFixed(1)}%)`;
            const displayLocalRate =
                formatNumber(autoConsumption, 'Wh') + autoProduction;
            return {
                id,
                dsoId,
                name,
                localRate: displayLocalRate,
                globalRate: formatNumber(consumption, 'Wh'),
            };
        });
    }
);

export const selectProducersMetrics = createSelector(
    selectActiveProducers,
    selectProductionByProducersMetricsData,
    selectAutoConsumptionByProducersMetrics,
    selectActiveProsumers,
    (producers, productionMetrics, autoConsumptionMetrics, activeProsumers) => {
        return producers.map((producer) => {
            const id = producer.id;
            const dsoId = producer.dsoId;
            const name = producer.name || dsoId;
            const consumption =
                productionMetrics.find(
                    ({ participantId }) =>
                        participantId.toString() === id.toString()
                )?.value || 0;
            const autoConsumption =
                autoConsumptionMetrics.find(
                    ({ participantId }) =>
                        participantId.toString() === id.toString()
                )?.value || 0;
            const autoProduction = ` (${(
                (consumption > 0 ? autoConsumption / consumption : 0) * 100
            ).toFixed(1)}%)`;
            const displayLocalRate =
                formatNumber(autoConsumption, 'Wh') + autoProduction;
            const isProsumer = activeProsumers.some(
                (producerId) => producerId === id
            );
            return {
                id,
                dsoId,
                name,
                localRate: displayLocalRate,
                globalRate: formatNumber(consumption, 'Wh'),
                isProsumer,
            };
        });
    }
);

export {
    selectSelectedDates,
    selectRange,
    selectSelectedRange,
    selectIsQueryingDataInRange,
    selectUploadedDataRange,
    selectGlobalConsumption,
    selectGlobalProduction,
    selectProductionByProducers,
    selectExcessByProducers,
    selectAutoProductionByProducers,
    selectAutoConsumptionByConsumers,
    selectGlobalConsumptionChartData,
    selectGlobalProductionChartData,
    selectConsumptionByConsumers,
    selectGesKpi,
    selectRangeLoading,
    selectDistribution,
    selectDistributionData,
    selectDistributionLoading,
};
