import { createSelector } from 'reselect';

const selectFunctionalities = (state) => state.functionalities;

const selectACI = createSelector(
    selectFunctionalities,
    (functionalities) => functionalities.ACI
);

const selectACType = createSelector(selectACI, (ACI) => ACI.ACType);

export { selectACType };
