// Consumption
import { createSelector } from 'reselect';
import { MetricNaturesEnum } from '../../../../../../app-config';
import { selectMetrics } from './metricsSelector';
import { selectACType } from '../../functionalitiesSelector';

// ACC
const selectAccAutoConsumptionByProducersMetrics = createSelector(
    selectMetrics,
    (metrics) => metrics[MetricNaturesEnum.ACC_AUTO_CONSUMPTION_BY_PRODUCERS]
);
export const selectAccAutoConsumptionByProducersMetricsData = createSelector(
    selectAccAutoConsumptionByProducersMetrics,
    (metrics) => metrics.data || []
);
const selectAccAutoConsumptionByProducersMetricsLoading = createSelector(
    selectAccAutoConsumptionByProducersMetrics,
    (metrics) => metrics.loading
);

// ACI
const selectAciAutoConsumptionByProducersMetrics = createSelector(
    selectMetrics,
    (metrics) => metrics[MetricNaturesEnum.ACI_AUTO_CONSUMPTION_BY_PRODUCERS]
);
const selectAciAutoConsumptionByProducersMetricsData = createSelector(
    selectAciAutoConsumptionByProducersMetrics,
    (metrics) => metrics.data || []
);
const selectAciAutoConsumptionByProducersMetricsLoading = createSelector(
    selectAciAutoConsumptionByProducersMetrics,
    (metrics) => metrics.loading
);

export const selectAutoConsumptionByProducersMetrics = createSelector(
    selectACType,
    selectAccAutoConsumptionByProducersMetricsData,
    selectAciAutoConsumptionByProducersMetricsData,
    (acType, accData, aciData) => (acType === 'ACC' ? accData : aciData)
);

export const selectAutoConsumptionByProducersMetricsLoading = createSelector(
    selectACType,
    selectAccAutoConsumptionByProducersMetricsLoading,
    selectAciAutoConsumptionByProducersMetricsLoading,
    (acType, accLoading, aciLoading) =>
        acType === 'ACC' ? accLoading : aciLoading
);
