import {
    INIT_ACCOUNT_OPERATION_BILLINGS,
    SET_ACCOUNT_OPERATION_BILLINGS,
} from './constants';

const initialState = [];

function accountReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_ACCOUNT_OPERATION_BILLINGS: {
            return [];
        }
        case SET_ACCOUNT_OPERATION_BILLINGS: {
            return [...action.operationBillings];
        }
        default:
            return state;
    }
}

export default accountReducer;
