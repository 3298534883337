import { INIT_DRAWER, SET_ITEM } from './constants';

const initialState = {
    item: '',
};

function itemReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ITEM: {
            return { item: action.item };
        }
        case INIT_DRAWER: {
            return { item: '' };
        }
        default:
            return state;
    }
}

export default itemReducer;
