import { INIT_ERROR, SET_ERROR } from './constants';

const initialState = {
    status: null,
    message: null,
    variant: null,
};

function errorReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ERROR: {
            return {
                status: action?.status || null,
                message: action?.message || null,
                variant: action?.variant || null,
                persist: action?.persist || null,
            };
        }
        case INIT_ERROR: {
            return {
                status: null,
                message: null,
                variant: null,
                persist: null,
            };
        }
        default:
            return state;
    }
}

export default errorReducer;
