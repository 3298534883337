import { SET_PRESIGNED_BILLING_LOGO } from './constants';

const initialState = {
    presignedBillingLogo: null,
};

function participantReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRESIGNED_BILLING_LOGO: {
            return {
                ...state,
                presignedBillingLogo: action.presignedBillingLogo,
            };
        }

        default:
            return state;
    }
}

export default participantReducer;
