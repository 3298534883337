import history from './history';
import dataProvider from './dataProvider';
import authProvider from './authProvider';

const congigureStore = {
    history,
    dataProvider,
    authProvider,
};

export default congigureStore;
