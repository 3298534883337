import client from '../../libs/client';

export class MaxRetries extends Error {
    name = 'MaxRetries';
}
export default class UploadGateway {
    uploadSibelgaLoadCurves = async (operationId, files) => {
        let formData = new FormData();

        files.forEach((file) => {
            formData.append('file', file);
            return formData;
        });
        await client.post(
            `operations/${operationId}/measurements/upload-permanent-dataset`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        // handle scalingo timeout response
        await sleep(10);
        const data = await getStatus(operationId, 20);
        return data;
    };
}

const getStatus = async (operationId, maxRetries) => {
    let retries = -1;
    while (retries < maxRetries) {
        const { data } = await client.get(
            `operations/${operationId}/measurements/upload-event`
        );
        if (data?.status !== 'started') {
            return data;
        }
        retries++;
        if (retries < maxRetries) {
            await sleep(30);
        }
    }
    throw new MaxRetries();
};

const sleep = (seconds) => {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};
