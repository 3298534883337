import client from '../../libs/client';

export default class CommunicationGateway {
    async sendCommunication(subject, body, recipients) {
        const res = await client.post('/communication/send', {
            subject,
            body,
            recipients,
        });
        return res;
    }
}
