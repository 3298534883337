import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
    getOperationParticipants,
    setError,
    setNotif,
    setPresignedBillingLogo,
} from '../../redux/actions';
import {
    GET_PRESIGNED_BILLING_LOGO,
    IMPORT_PARTICIPANTS,
    UPDATE_PARTICIPANT,
} from '../../redux/reducers/constants';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';
import i18n from 'i18next';

function* updateParticipant(action) {
    try {
        const { participant, newParticipant, formData } = action;
        const operation = yield select(selectSelectedOperation);
        const participantGateway = yield getContext('participantGateway');
        let billingLogoPath;
        if (formData) {
            billingLogoPath = yield call(
                participantGateway.uploadBillingLogo,
                participant,
                formData
            );
        }
        yield call(
            participantGateway.updateParticipant,
            participant,
            newParticipant,
            billingLogoPath
        );
        yield put(getOperationParticipants(operation));
    } catch (error) {
        yield put(
            setError({
                status: error?.response?.status,
                message:
                    'Nous ne pouvons pas créer un compte utilisateur avec cette adresse. Veuillez contacter le support à support@enogrid.com ou essayer avec une autre adresse e-mail.',
                variant: 'error',
            })
        );
        console.error(error);
    }
}

function* getPresignedBillingLogo(action) {
    try {
        const { participant } = action;
        const participantGateway = yield getContext('participantGateway');
        const presignedBillingLogo = yield call(
            participantGateway.getPresignedBillingLogo,
            participant
        );
        yield put(setPresignedBillingLogo(presignedBillingLogo));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* importOperationParticipants(action) {
    const { operation, file } = action;

    const participantGateway = yield getContext('participantGateway');
    try {
        yield put(
            setNotif({
                message: i18n.t(
                    'enoapp.administrative.participants.import.inProgress'
                ),
            })
        );
        const importResults = yield call(
            participantGateway.importParticipants,
            operation,
            file
        );
        if (importResults.errors.length === 0) {
            yield put(
                setNotif({
                    message: i18n.t(
                        'enoapp.administrative.participants.import.done'
                    ),
                })
            );
        } else if (importResults.success.length > 0) {
            yield put(
                setNotif({
                    message: i18n.t(
                        'enoapp.administrative.participants.import.partial'
                    ),
                    variant: 'warning',
                    persist: true,
                })
            );
        }

        if (importResults.errors.length > 0) {
            let errorMessages = [];
            for (let error of importResults.errors) {
                // I18N error if possible
                const i18nErrorMessage = error.message.includes(' ')
                    ? error.message
                    : i18n.t('error.' + error.message);

                errorMessages.push(
                    i18n.t(
                        'enoapp.administrative.participants.import.errorLine',
                        {
                            lineNumber: error.row,
                        }
                    ) +
                        i18nErrorMessage +
                        ' ' +
                        i18n.t(
                            'enoapp.administrative.participants.participantCouldNotBeCreated',
                            {
                                dsoId:
                                    error.data['PRM'] ||
                                    error.data['prm'] ||
                                    error.data['ean'] ||
                                    error.data['EAN'],
                            }
                        )
                );
            }
            yield put(
                setError({
                    message: errorMessages.join('\n'),
                    persist: true,
                })
            );
        }
        yield put(getOperationParticipants(operation));
    } catch (error) {
        let errorMessage = '';

        errorMessage = error.message || error.response.message;
        yield put(
            setError({
                status: error.response && error.response.status,
                message: errorMessage,
            })
        );
        console.error(error);
    }
}

export function* updateParticipantSaga() {
    yield takeLatest(UPDATE_PARTICIPANT, updateParticipant);
}

export function* getPresignedBillingLogoSaga() {
    yield takeLatest(GET_PRESIGNED_BILLING_LOGO, getPresignedBillingLogo);
}

function* importOperationParticipantsSaga() {
    yield takeLatest(IMPORT_PARTICIPANTS, importOperationParticipants);
}

const participantSagas = [
    updateParticipantSaga,
    getPresignedBillingLogoSaga,
    importOperationParticipantsSaga,
];

export default participantSagas;
