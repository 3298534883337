const descendingComparator = (a, b, orderBy, type) => {
    if (!a[type] || !b[type]) return 0;
    if (b[type][orderBy] < a[type][orderBy]) {
        return -1;
    }
    if (b[type][orderBy] > a[type][orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy, type) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, type)
        : (a, b) => -descendingComparator(a, b, orderBy, type);
};

const tableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export { tableSort, getComparator };
