import { createSelector } from 'reselect';
import { selectMeasurement } from '../sharedSelectors';
import { lightenDarkenColor } from '../../../../../adapters/primary/libs/lightenDarkenColor';

export const selectMetrics = createSelector(
    selectMeasurement,
    (measurement) => measurement.metrics
);
export const getByProducersPieChartColors = (
    basicColor,
    numberOfColors,
    maxPercent
) => {
    const interval =
        numberOfColors > 1 ? (maxPercent * 2) / (numberOfColors - 1) : 0;
    const color = [];
    for (let i = 0; i < numberOfColors; i++) {
        color.push(lightenDarkenColor(basicColor, maxPercent - interval * i));
    }
    return color;
};
