import { AllocationAlgorithmTypeEnum } from '../../../../../app-config';
import { NEW_POOL_PREFIX } from '../../../primary/ui/enoapp/containers/OperationEdit/ByPoolParams';
import client from '../../libs/client';

const URL_BASE = '/energy-allocation';

export class EnergyAllocationGateway {
    getAllocationAlgorithmConfig = async (operationId) => {
        const { data } = await client.get(
            `${URL_BASE}/config/last?operationId=${operationId}`
        );
        return data;
    };

    getParticipantsParameters = async (configId) => {
        // const response = [];
        // const chunkSize = 50;
        // const participantsIds = [...consumersIds, ...producersIds];
        // for (let i = 0; i < participantsIds.length; i += chunkSize) {
        //     const chunk = participantsIds.slice(i, i + chunkSize);
        //     const participantsIdsQuery = chunk
        //         .map((id) => `participantsIds=${id}`)
        //         .join('&');
        //
        //     response.push(...data);
        // }
        const { data } = await client.get(
            `${URL_BASE}/active-config-param?allocationAlgorithmId=${configId}`
        );
        return this._fromObject(data);
    };

    _fromObject(dataArray) {
        const result = {};

        dataArray.forEach((item) => {
            const { consumerId, producerId, value } = item;

            if (producerId) {
                if (!result[producerId]) {
                    result[producerId] = {};
                }
                result[producerId][consumerId] = value;
            } else {
                result[consumerId] = value;
            }
        });

        return result;
    }

    /**
     * Due to compatibility issues :
     * Store format :
     * {
     *     <consumerId>: <value>
     * }
     * or
     * {
     *     <consumerId>: {
     *         <producerId1>: <value1>,
     *         <producerId2>: <value2>
     *     }
     * }
     *
     * Enopower needed format :
     * [
     *     {"consumerId": <consumerId>, "value": <value>}
     * ]
     * or
     * [
     *     {
     *         "consumerId": <consumerId>,
     *         "producerId1": <producerId1>,
     *         "value": <value1>
     *     },
     *     {
     *         "consumerId": <consumerId>,
     *         "producerId2": <producerId2>,
     *         "valu": <value2>
     *     }
     * ]
     */
    _serializeParticipantsParameters = (input) => {
        const newParticipantsParametersEntries = Object.entries(input);
        return newParticipantsParametersEntries.reduce(
            (acc, [consumerId, value]) => {
                if (typeof value === 'object') {
                    const valueEntries = Object.entries(value);
                    valueEntries.forEach(([producerId, value]) => {
                        acc.push({
                            consumerId: Number(producerId), // TODO do better than this mixup names
                            producerId: Number(consumerId), // TODO do better  than this mixup names
                            value: Number(value),
                        });
                    });
                } else {
                    acc.push({
                        consumerId: Number(consumerId),
                        producerId: undefined,
                        value: Number(value),
                    });
                }
                return acc;
            },
            []
        );
    };

    createConfig = async (operationId, type, params) => {
        let serializedParams = null;
        if (params && type !== AllocationAlgorithmTypeEnum.BY_POOL) {
            serializedParams = this._serializeParticipantsParameters(params);
        }
        const { data: createdConfig } = await client.post(
            `${URL_BASE}/config?operationId=${operationId}`,
            {
                type: type,
                params: serializedParams,
            }
        );

        if (type === AllocationAlgorithmTypeEnum.BY_POOL) {
            await this.createPools(createdConfig.id, params);
        }
        return createdConfig;
    };

    createParticipantParameter = async (
        consumerId,
        producerId,
        configId,
        value
    ) => {
        const { data } = await client.post(
            `${URL_BASE}/config-param?consumerId=${consumerId}&producerId=${producerId}`,
            {
                allocationAlgorithmId: configId,
                value,
            }
        );
        return data;
    };

    createPools = async (configId, poolParams) => {
        const poolsDTO = [];
        for (const [priority, pool] of Object.entries(poolParams)) {
            poolsDTO.push({
                id: pool.id.includes(NEW_POOL_PREFIX) ? null : Number(pool.id),
                name: pool.name,
                percentageProduction: pool.percentageProduction,
                priority: Number(priority),
                surplusDestinationPoolName: pool.surplusDestinationPoolName,
                type: pool.algorithm,
                consumersInPool: Object.keys(pool.withinPoolProductionParams),
            });
        }

        const { data } = await client.post(
            `${URL_BASE}/config-pools?allocationAlgorithmId=${configId}`,
            {
                pools: poolsDTO,
            }
        );

        // Send parameters
        for (const pool of Object.values(poolParams)) {
            if (pool.algorithm === AllocationAlgorithmTypeEnum.BY_DEFAULT) {
                continue;
            }
            const poolId = data.filter((p) => p.name === pool.name)[0].id;
            for (const [consumerId, value] of Object.entries(
                pool.withinPoolProductionParams
            )) {
                await client.post(
                    `${URL_BASE}/config-param?consumerId=${consumerId}&producerId=null`,
                    {
                        allocationPoolParametersId: poolId,
                        value,
                    }
                );
            }
        }
    };

    getPools = async (configId) => {
        const { data: poolsDTO } = await client.get(
            `${URL_BASE}/active-config-pools?allocationAlgorithmId=${configId}`
        );
        const poolParams = {};
        for (const poolDTO of poolsDTO) {
            let parameters = {};
            if (poolDTO.type === AllocationAlgorithmTypeEnum.BY_DEFAULT) {
                // consumersInPool is only used to create ByDefault list of participants
                // For other algorithm, participants are already listed in parameters dict
                for (const consumerId of poolDTO.consumersInPool) {
                    parameters[consumerId] = 1;
                }
            } else {
                if (poolDTO.parameters) {
                    for (const params of poolDTO.parameters) {
                        parameters[params.consumerId] = params.value;
                    }
                }
            }

            poolParams[poolDTO.priority] = {
                id: String(poolDTO.id),
                name: poolDTO.name,
                percentageProduction: poolDTO.percentageProduction,
                surplusDestinationPoolName: poolDTO.surplusDestinationPoolName,
                algorithm: poolDTO.type,
                withinPoolProductionParams: parameters,
            };
        }
        return poolParams;
    };

    getSeasonality = async (operationId) => {
        const { data } = await client.get(
            `${URL_BASE}/seasonality?operationId=${operationId}`
        );
        return data.seasonality;
    };

    upsertSeasonality = async (operationId, seasonality) => {
        await client.post(
            `${URL_BASE}/seasonality?operationId=${operationId}`,
            seasonality
        );
    };
}
