import { SET_SIBELGA_LOAD_CURVES_UPLOAD_LOADER } from './constants';
import { combineReducers } from 'redux';

function loaderReducer(
    state = { isLoading: false, operationId: null },
    action
) {
    switch (action.type) {
        case SET_SIBELGA_LOAD_CURVES_UPLOAD_LOADER: {
            const { isLoading, operationId } = action;
            return { isLoading, operationId };
        }
        default:
            return state;
    }
}

export default combineReducers({
    loader: loaderReducer,
});
