import { INIT_BILLING_CONFIG, SET_CONFIGS, SET_CONTRACTS } from './constants';
import { combineReducers } from 'redux';

const initialState = {
    participants: [],
};

function billingConfigReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CONFIGS: {
            return { ...state, participants: [...action.configs] };
        }
        case INIT_BILLING_CONFIG: {
            return initialState;
        }
        default:
            return state;
    }
}

function billingContractReducer(state = [], action) {
    switch (action.type) {
        case SET_CONTRACTS: {
            return action.contracts;
        }
        case INIT_BILLING_CONFIG: {
            return [];
        }
        default:
            return state;
    }
}

export default combineReducers({
    configs: billingConfigReducer,
    contracts: billingContractReducer,
});
