import {
    INIT_MEASUREMENT,
    INIT_RANGE,
    INIT_SELECTED_DATES,
    RANGE_LOADING,
    SET_RANGE,
    SET_SELECTED_DATES,
} from '../constants';

const initialState = {
    selectedDates: {
        from: null,
        to: null,
    },
    dates: {
        from: null,
        to: null,
    },
    range: {
        uploadedData: {
            from: null,
            to: null,
        },
        sgeTiersData: {
            from: null,
            to: null,
        },
    },
    loading: false,
};

export function filterReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SELECTED_DATES: {
            return {
                ...state,
                selectedDates: action.selectedDates,
            };
        }
        case SET_RANGE: {
            return { ...state, range: action.range };
        }
        case RANGE_LOADING: {
            return {
                ...state,
                loading: action.loading,
            };
        }

        case INIT_SELECTED_DATES: {
            return {
                ...state,
                selectedDates: {
                    from: null,
                    to: null,
                },
                dates: {
                    from: null,
                    to: null,
                },
            };
        }

        case INIT_RANGE: {
            return {
                ...state,
                range: {
                    uploadedData: {
                        from: null,
                        to: null,
                    },
                    sgeTiersData: {
                        from: null,
                        to: null,
                    },
                },
            };
        }

        case INIT_MEASUREMENT: {
            return {
                ...initialState,
                dates: { ...state.selectedDates },
            };
        }
        default:
            return state;
    }
}
