import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
    setError,
    setOperationCreditorsDetails,
    setProducerMandates,
    getOperationCreditorsDetails as getOperationCreditorsDetailsAction,
    getProducerMandates as getProducerMandatesAction,
} from '../../redux/actions';
import {
    CREATE_PARTICIPANT_MANDATES_DETAILS,
    CREATE_MULTIPLE_MANDATES_FOR_PRODUCER,
    CREATE_CREDITOR_DETAIL,
    DOWNLOAD_SEPA_FILE,
    GET_PRODUCER_MANDATES,
    GET_OPERATION_CREDITORS_DETAILS,
} from '../../redux/reducers/constants';
import {
    makeSelectConsumerName,
    makeSelectProducerName,
    selectProducers,
} from '../../redux/selectors/participantSelectors';
import i18n from 'i18next';

function* createCreditorDetail(action) {
    const { participantId, paymentDetail, isUpdate } = action;
    const paymentGateway = yield getContext('paymentGateway');
    try {
        yield call(
            paymentGateway.createParticipantPaymentDetail,
            participantId,
            paymentDetail
        );
        if (isUpdate) {
            yield put(getOperationCreditorsDetailsAction());
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.response?.data?.message || error.message,
            })
        );
    }
}

function* createParticipantMandatesDetails(action) {
    const { participantId, mandatesDetails, isUpdate } = action;
    const paymentGateway = yield getContext('paymentGateway');
    try {
        yield call(
            paymentGateway.createParticipantMandatesDetails,
            participantId,
            mandatesDetails
        );
        if (isUpdate) {
            yield put(
                getProducerMandatesAction(Object.keys(mandatesDetails)[0])
            );
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.response?.data?.message || error.message,
            })
        );
    }
}

function* createMultipleMandatesForProducer(action) {
    const { producerId, mandatesDetails } = action;
    const paymentGateway = yield getContext('paymentGateway');
    try {
        const results = yield call(
            paymentGateway.createMultipleMandatesForProducer,
            producerId,
            mandatesDetails
        );
        if (results.mandates && results.mandates.length > 0) {
            const mandates = yield call(
                paymentGateway.getProducerMandates,
                producerId
            );
            yield put(setProducerMandates(mandates));
        }
        if (results.errors && results.errors.length > 0) {
            yield put(
                setError({
                    message:
                        'Erreurs pour les mandats :\n' +
                        results.errors
                            .map(
                                (error) =>
                                    `${error.mandateId} : ${i18n.t(
                                        error.message
                                    )}`
                            )
                            .join('\n'),
                })
            );
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.response?.data?.message || error.message,
            })
        );
    }
}

function* downloadSepaFile(action) {
    try {
        const { producer, billingPeriodEnd } = action;
        const paymentGateway = yield getContext('paymentGateway');
        const producerName = yield select(makeSelectProducerName(producer.id));
        yield call(
            paymentGateway.downloadSepaFile,
            producer.id,
            billingPeriodEnd,
            producerName
        );
    } catch (error) {
        if (error.response.data && error.response.data.errors) {
            const missedCreditorsDetails = [];
            const missedDebtorsDetails = [];
            for (let i = 0; i < error.response.data.errors.length; i++) {
                const err = error.response.data.errors[i];
                const participantId = err.participantId;
                if (err.message === 'error.payment.mandate.not_found') {
                    const consumerName = yield select(
                        makeSelectConsumerName(participantId)
                    );
                    missedDebtorsDetails.push(consumerName);
                }
                if (err.message === 'error.payment.creditor.not_found') {
                    const producerName = yield select(
                        makeSelectProducerName(participantId)
                    );
                    missedCreditorsDetails.push(producerName);
                }
            }
            if (missedCreditorsDetails.length) {
                yield put(
                    setError({
                        message: `${i18n.t(
                            'enoapp.administrative.payment.creditorMissingDataMessage'
                        )}: ${missedCreditorsDetails.join(', ')}`,
                    })
                );
            }
            if (missedDebtorsDetails.length) {
                yield put(
                    setError({
                        message: `${i18n.t(
                            'enoapp.administrative.payment.debtorMissingDataMessage'
                        )}: ${missedDebtorsDetails.join(', ')}`,
                    })
                );
            }
        } else {
            yield put(
                setError({
                    status: error.response && error.response.status,
                    message: error.response?.data?.message || error.message,
                })
            );
        }
    }
}

function* getOperationCreditorsDetails() {
    const producers = yield select(selectProducers);
    const paymentGateway = yield getContext('paymentGateway');
    try {
        const creditors = yield call(
            paymentGateway.getCreditorsDetailsByProducers,
            producers.map((producer) => producer.id)
        );
        yield put(setOperationCreditorsDetails(creditors));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.response?.data?.message || error.message,
            })
        );
    }
}

function* getProducerMandates(action) {
    const { producerId } = action;
    const paymentGateway = yield getContext('paymentGateway');
    try {
        const mandates = yield call(
            paymentGateway.getProducerMandates,
            producerId
        );
        yield put(setProducerMandates(mandates));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.response?.data?.message || error.message,
            })
        );
    }
}

export function* createCreditorDetailSaga() {
    yield takeLatest(CREATE_CREDITOR_DETAIL, createCreditorDetail);
}

export function* createParticipantMandatesDetailsSaga() {
    yield takeLatest(
        CREATE_PARTICIPANT_MANDATES_DETAILS,
        createParticipantMandatesDetails
    );
}

export function* createMultipleMandatesForProducerSaga() {
    yield takeLatest(
        CREATE_MULTIPLE_MANDATES_FOR_PRODUCER,
        createMultipleMandatesForProducer
    );
}

export function* downloadSepaFileSaga() {
    yield takeLatest(DOWNLOAD_SEPA_FILE, downloadSepaFile);
}

export function* getOperationCreditorsDetailsSaga() {
    yield takeLatest(
        GET_OPERATION_CREDITORS_DETAILS,
        getOperationCreditorsDetails
    );
}

export function* getProducerMandatesSaga() {
    yield takeLatest(GET_PRODUCER_MANDATES, getProducerMandates);
}

const paymentSagas = [
    createCreditorDetailSaga,
    createParticipantMandatesDetailsSaga,
    createMultipleMandatesForProducerSaga,
    downloadSepaFileSaga,
    getOperationCreditorsDetailsSaga,
    getProducerMandatesSaga,
];

export default paymentSagas;
