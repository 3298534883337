import { SET_AC_TYPE, SET_EMISSION } from './constants';

const initialState = {
    ACI: {
        ACType: 'ACC',
    },
};

function functionalitiesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_AC_TYPE: {
            return { ...state, ACI: { ...state.ACI, ACType: action.ACType } };
        }
        case SET_EMISSION: {
            return { ...state, emission: action.emission };
        }
        default:
            return state;
    }
}

export default functionalitiesReducer;
