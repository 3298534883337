import { ParticipantMapper } from '../../../../mappers/participant/participant.mapper';
import client from '../../libs/client';
import axios from 'axios';

export default class OperationGateway {
    error;

    setError = (error) => {
        this.error = error;
    };

    getOrganizerByJwt = async () => {
        const { data: organizer } = await client.get('/organizers/by-jwt');
        return organizer;
    };

    getOperations = async (operationsIds) => {
        const operations = await Promise.all(
            operationsIds.map((opId) => client.get(`/operations/${opId}`))
        );
        return operations.map((op) => op.data);
    };

    getObserverOperations = async () => {
        const { data: operations } = await client.get(
            `/observers/by-jwt/operations?detailed=false`
        );
        return operations;
    };
    getObserverOperationsWithDetailNumbers = async () => {
        const { data: operations } = await client.get(
            `/observers/by-jwt/operations?detailed=true`
        );
        return operations;
    };
    getOrganizerOperations = async (organizerId) => {
        const { data: operations } = await client.get(
            `/organizers/${organizerId}/operations?detailed=false`
        );
        return operations;
    };
    getOrganizerOperationsWithDetailNumbers = async (organizerId) => {
        const { data: operations } = await client.get(
            `/organizers/${organizerId}/operations?detailed=true`
        );
        return operations;
    };

    getParticipantsByJwt = async () => {
        const { data: participants } = await client.get(`/participants/by-jwt`);
        return participants.map((p) => new ParticipantMapper(p));
    };

    getOperationParticipants = async (operationId, from, to) => {
        const dateFrom = from ? from.toISOString() : '';
        const dateTo = to ? to.toISOString() : '';
        const { data: participants } = await client.get(
            `/operations/${operationId}/participants?dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        return participants.map((p) => new ParticipantMapper(p));
    };

    getOperationObservers = async (operationId) => {
        const { data: observers } = await client.get(
            `/operations/${operationId}/observers`
        );
        return observers;
    };

    getOperationInterlocutor = async (operationId) => {
        try {
            const interlocutor = await client.get(
                `/operations/${operationId}/interlocutor`
            );
            return interlocutor.data;
        } catch (error) {
            console.log(error);
            return {};
        }
    };

    updateOperation = async (operation) => {
        const { data: updatedOperation } = await client.put(
            `/operations/${operation.id}`,
            operation
        );
        return updatedOperation;
    };

    updateOperationTheme = async (operationId, operationTheme) => {
        const { data: updatedTheme } = await client.put(
            `/operations/${operationId}/theme`,
            operationTheme
        );
        return updatedTheme;
    };

    uploadLogo = async (operation, formData) => {
        const { data } = await client.post(
            `operations/${operation.id}/upload-logo`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return data;
    };

    getPresignedLogo = async (operation) => {
        if (operation.theme && operation.theme.logo) {
            const {
                data: { url },
            } = await client.get(`/operations/${operation.id}/presigned-logo`);
            try {
                const { data: logo } = await axios(url, {
                    method: 'GET',
                    responseType: 'blob',
                });
                operation.presignedLogo = URL.createObjectURL(logo);
            } catch (err) {
                console.log(err);
            }
        }
    };

    getPresignedLogos = async (operations) => {
        const operationIds = operations.map((op) => op.id);
        if (operationIds.length > 0) {
            const { data: urls } = await client.get(
                `/theme/presigned-logos?operationIds=${operationIds.join(',')}`
            );

            await Promise.all(
                operations.map(async (op) => {
                    const url = urls.find((url) => url.id === op.id)?.url;
                    if (url) {
                        try {
                            const { data: logo } = await axios(url, {
                                method: 'GET',
                                responseType: 'blob',
                            });
                            op.presignedLogo = URL.createObjectURL(logo);
                        } catch (err) {
                            console.log(err);
                        }
                    }
                })
            );
        }
    };

    getAllOperations = async () => {
        const { data: operations } = await client.get(`admin/operations`);
        return operations;
    };
}
