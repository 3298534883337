import { createSelector } from 'reselect';
import { contractTypesENUM } from '../../../../app-config';
import {
    makeSelectConsumerEntityType,
    selectConsumers,
} from './participantSelectors';

export const selectBillCheck = (state) => state.billCheck;

export const selectConsumptionsAndEconomies = createSelector(
    selectBillCheck,
    (billCheck) => billCheck.data
);

export const selectConsumptionsAndEconomiesLoading = createSelector(
    selectBillCheck,
    (billCheck) => billCheck.loading
);

export const selectTotalEconomies = createSelector(
    selectConsumptionsAndEconomies,
    (consumptionsAndEconomies) => {
        const total = {
            economy: 0,
            consumption: 0,
            autoconsumption: 0,
        };
        return consumptionsAndEconomies.reduce((acc, curr) => {
            acc.economy += curr.economy;
            acc.consumption += curr.consumption;
            acc.autoconsumption += curr.autoconsumption;
            return acc;
        }, total);
    }
);

export const selectGlobalForm = createSelector(
    selectBillCheck,
    (billCheck) => billCheck.globalForm
);

export const selectStartDate = createSelector(
    selectGlobalForm,
    (form) => form.startDate
);

export const selectEndDate = createSelector(
    selectGlobalForm,
    (form) => form.endDate
);

export const selectIsDateRangeValid = createSelector(
    selectStartDate,
    selectEndDate,
    (startDate, endDate) => startDate && endDate
);

export const selectParticipantsForm = createSelector(
    selectGlobalForm,
    (form) => {
        const { startDate, endDate, ...participantsForm } = form;
        return participantsForm || {};
    }
);
export const makeSelectParticipantForm = (id) =>
    createSelector(selectGlobalForm, (globalForm) => globalForm[id]);

export const makeSelectContractType = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.contractType);

export const makeSelectConsumerType = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.consumerType);

export const makeSelectBasekWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.basekWhPrice);

export const makeSelectPeakkWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.peakkWhPrice);

export const makeSelectOffPeakkWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.offPeakkWhPrice);

export const makeSelectSummerPeakkWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.summerPeakkWhPrice);

export const makeSelectSummerOffPeakkWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.summerOffPeakkWhPrice);

export const makeSelectWinterPeakkWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.winterPeakkWhPrice);

export const makeSelectWinterOffPeakkWhPrice = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.winterOffPeakkWhPrice);

export const makeSelectIsOnTRV = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.isOnTRV);

export const makeSelectPeakHourStart = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.peakHourStart);

export const makeSelectOffPeakHourStart = (id) =>
    createSelector(selectGlobalForm, (form) => form[id]?.offPeakHourStart);

export const selectParticipantForm = createSelector(
    selectBillCheck,
    (billCheck) => billCheck.participantForm
);

export const selectContractType = createSelector(
    selectParticipantForm,
    (form) => form?.contractType
);

export const selectConsumerType = createSelector(
    selectParticipantForm,
    (form) => form?.consumerType
);

export const selectBasekWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.basekWhPrice
);

export const selectPeakkWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.peakkWhPrice
);

export const selectOffPeakkWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.offPeakkWhPrice
);

export const selectSummerPeakkWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.summerPeakkWhPrice
);

export const selectSummerOffPeakkWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.summerOffPeakkWhPrice
);

export const selectWinterPeakkWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.winterPeakkWhPrice
);

export const selectWinterOffPeakkWhPrice = createSelector(
    selectParticipantForm,
    (form) => form?.winterOffPeakkWhPrice
);

export const selectIsOnTRV = createSelector(
    selectParticipantForm,
    (form) => form?.isOnTRV
);

export const selectPeakHourStart = createSelector(
    selectParticipantForm,
    (form) => form?.peakHourStart
);

export const selectOffPeakHourStart = createSelector(
    selectParticipantForm,
    (form) => form?.offPeakHourStart
);

export const selectIsFormValid = createSelector(
    selectParticipantForm,
    (form) => {
        const {
            consumerType,
            contractType,
            basekWhPrice,
            peakkWhPrice,
            offPeakkWhPrice,
            summerPeakkWhPrice,
            summerOffPeakkWhPrice,
            winterPeakkWhPrice,
            winterOffPeakkWhPrice,
            peakHourStart,
            offPeakHourStart,
            isOnTRV,
        } = form;
        if (!consumerType || !contractType) {
            return false;
        }
        if (!isOnTRV) {
            if (
                contractType === contractTypesENUM.BASE &&
                isNaN(basekWhPrice)
            ) {
                return false;
            }
            if (
                contractType === contractTypesENUM.DOUBLE_PRICE &&
                (isNaN(peakkWhPrice) || isNaN(offPeakkWhPrice))
            ) {
                return false;
            }
            if (
                contractType === contractTypesENUM.QUADRUPLE_PRICE &&
                (isNaN(summerOffPeakkWhPrice) ||
                    isNaN(summerPeakkWhPrice) ||
                    isNaN(winterOffPeakkWhPrice) ||
                    isNaN(winterPeakkWhPrice))
            ) {
                return false;
            }
        }
        if (
            [
                contractTypesENUM.DOUBLE_PRICE,
                contractTypesENUM.QUADRUPLE_PRICE,
            ].includes(contractType)
        ) {
            if (!peakHourStart || !offPeakHourStart) {
                return false;
            }
        }
        return true;
    }
);

export const selectSelectedParticipants = createSelector(
    selectBillCheck,
    (billCheck) => billCheck.selectedParticipants
);

export const selectIsApplyConfigButtonEnable = createSelector(
    selectParticipantForm,
    selectSelectedParticipants,
    (form, selectedParticipants) =>
        Object.keys(form).length && selectedParticipants.length
);

export const selectTRVPrice = createSelector(
    selectBillCheck,
    (billCheck) => billCheck.trvPrice
);

export const selectConfiguredConsumers = createSelector(
    selectConsumers,
    selectGlobalForm,
    (consumers, globalForm) =>
        consumers.filter((consumer) => globalForm[consumer.id])
);

export const selectNonConfiguredConsumers = createSelector(
    selectConsumers,
    selectGlobalForm,
    (consumers, globalForm) =>
        consumers.filter((consumer) => !globalForm[consumer.id])
);

export const makeSelectIsConsumerChecked = (id) =>
    createSelector(selectSelectedParticipants, (selectedParticipants) =>
        selectedParticipants.includes(id)
    );

export const makeSelectShowConsumerTypeWarning = (id) =>
    createSelector(
        makeSelectIsConsumerChecked(id),
        makeSelectConsumerEntityType(id),
        selectConsumerType,
        (isChecked, entityType, consumerType) =>
            isChecked &&
            entityType &&
            consumerType &&
            entityType !== consumerType
    );

export const makeSelectShowPermanentConsumerTypeWarning = (id) =>
    createSelector(
        makeSelectConsumerEntityType(id),
        makeSelectConsumerType(id),
        (entityType, consumerType) =>
            entityType && consumerType && entityType !== consumerType
    );

export const makeSelectOpenApplyConfigConfirmation = (selectedIds) =>
    createSelector(
        selectedIds.map(makeSelectShowConsumerTypeWarning),
        (...showWarnings) => showWarnings.some((showWarning) => showWarning)
    );
