import {
    INIT_MEASUREMENT,
    SET_DISTRIBUTION_DATA,
    SET_DISTRIBUTION_LOADING,
} from '../constants';

const initialState = {
    loading: false,
    data: null,
};

export function distributionReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DISTRIBUTION_DATA: {
            return {
                ...state,
                data: action.distribution,
            };
        }

        case SET_DISTRIBUTION_LOADING: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case INIT_MEASUREMENT: {
            return { ...initialState };
        }
        default:
            return state;
    }
}
