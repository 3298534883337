import { combineReducers } from 'redux';
import operationReducer from './reducers/operationReducer';
import accountReducer from './reducers/accountReducer';
import accountOperationBillingsReducer from './reducers/accountOperationBillingsReducer';
import operationInterlocutorReducer from './reducers/operationInterlocutorReducer';
import participantReducer from './reducers/participantReducer';
import measurementReducer from './reducers/measurementReducer/measurementReducer';
import routeReducer from './reducers/routeReducer';
import itemReducer from './reducers/drawerReducer';
import loginReducer from './reducers/loginReducer';
import errorReducer from './reducers/errorReducer';
import functionalitiesReducer from './reducers/functionalitiesReducer';
import billingReducer from './reducers/billingReducer';
import billingConfigReducer from './reducers/billingConfigReducer';
import navigationReducer from './reducers/navigationReducer';
import billCheckReducer from './reducers/billCheckReducer';
import communicationReducer from './reducers/communicationReducer';
import { connectRouter } from 'connected-react-router';
import { adminReducer } from 'react-admin';
import { energyAllocationReducer } from './reducers/energyAllocationReducer';
import notifReducer from './reducers/notifReducer';
import paymentReducer from './reducers/paymentReducer';
import uploadReducer from './reducers/uploadReducer';
import billingNewReducer from './reducers/billingNewReducer';

const rootReducer = (history) =>
    combineReducers({
        // enoapp store
        account: accountReducer,
        accountOperationBillings: accountOperationBillingsReducer,
        operation: operationReducer,
        operationInterlocutor: operationInterlocutorReducer,
        participant: participantReducer,
        measurement: measurementReducer,
        route: routeReducer,
        drawer: itemReducer,
        authentication: loginReducer,
        billing: billingReducer,
        billingNew: billingNewReducer,
        functionalities: functionalitiesReducer,
        error: errorReducer,
        notif: notifReducer,
        navigation: navigationReducer,
        billCheck: billCheckReducer,
        communication: communicationReducer,
        energyAllocation: energyAllocationReducer,
        payment: paymentReducer,
        upload: uploadReducer,
        // react-admin store
        billingConfig: billingConfigReducer,
        router: connectRouter(history),
        admin: adminReducer,
    });

export default rootReducer;
