import { createSelector } from 'reselect';
import { selectRange } from './measurementSelectors/measurementSelectors';
import { selectSelectedOperation } from './operationSelectors';
import moment from 'moment';
import {
    selectConsumers,
    selectMapParticipantIdToActivationRange,
} from './participantSelectors';
import { lightenDarkenColor } from '../../../adapters/primary/libs/lightenDarkenColor';

const selectConfigs = (state) => state.billingConfig.configs;

const selectStoreConfigs = createSelector(
    selectConfigs,
    (configs) => configs.participants
);

const selectContracts = (state) => state.billingConfig.contracts;

const selectBills = (state) => state.billing;

const selectSelectedProducersIds = (_, props) =>
    props.newConfig.producers.map((prod) => prod.id);

const selectEnergyType = (_, props) => props.energyType;

const selectParticipantsBills = createSelector(
    selectBills,
    (bills) => bills.participants
);

const selectDownloadLoading = createSelector(
    selectBills,
    (billing) => billing.downloadLoading
);

const selectMapConfigIdToConfig = createSelector(
    selectStoreConfigs,
    (configs) => {
        return configs.reduce((acc, config) => {
            acc[config.id] = config;
            return acc;
        }, {});
    }
);

export const makeSelectContracts = (configId) =>
    createSelector(
        selectMapConfigIdToContracts,
        (contracts) => contracts[configId]
    );

export const makeSelectConfig = (id) =>
    createSelector(
        makeSelectContracts(id),
        selectMapConfigIdToConfig,
        (contracts, configs) => {
            return {
                ...configs[id],
                startDate: contracts[0]?.startDate,
            };
        }
    );

const selectParticipantsConfigs = createSelector(
    selectMapConfigIdToConfig,
    selectContracts,
    (configs, contracts) => {
        const configsWithParticipants = JSON.parse(JSON.stringify(configs));
        if (!contracts.length) return [];
        contracts.forEach(({ producerId, consumerId, configId, startDate }) => {
            const config = configsWithParticipants[configId];
            if (!config) return;
            if (!config.producers) {
                config.producers = new Set();
                config.consumers = new Set();
                config.startDate = startDate;
            }
            config.producers.add(producerId);
            config.consumers.add(consumerId);
        });
        return Object.values(configsWithParticipants)
            .filter((config) => !!config.producers)
            .map((config) => {
                return {
                    ...config,
                    producers: Array.from(config.producers),
                    consumers: Array.from(config.consumers),
                };
            });
    }
);

export const selectMapConfigIdToContracts = createSelector(
    selectContracts,
    (contracts) =>
        contracts.reduce((acc, contract) => {
            if (!acc[contract.configId]) {
                acc[contract.configId] = [];
            }
            acc[contract.configId].push(contract);
            return acc;
        }, {})
);

const selectEnabledParticipants = createSelector(
    selectSelectedOperation,
    selectEnergyType,
    (operation, energyType) => {
        return operation
            ? operation[energyType].map((participant) => ({
                  id: participant.id,
                  name:
                      participant.billingDetail &&
                      participant.billingDetail.name,
                  prm: participant.dsoId,
                  dsoId: participant.dsoId,
                  treasury: participant.billingDetail.treasury,
              }))
            : [];
    }
);

const selectAnniversaryDate = createSelector(
    selectSelectedOperation,
    selectSelectedProducersIds,
    selectRange,
    (operation, selectedProducers, range) => {
        let oldestStartDate = moment();
        const producers = operation.producers.filter(({ id }) =>
            selectedProducers.includes(id)
        );
        for (let idx = 0; idx < producers.length; idx++) {
            const producer = producers[idx];
            const producerStartDate = producer.activationRange[0].value;
            if (!producerStartDate) {
                oldestStartDate = null;
                break;
            }
            oldestStartDate = moment.min(
                oldestStartDate,
                moment(producerStartDate)
            );
        }
        return oldestStartDate || range.from;
    }
);

const selectParticipantsBillsGroupedByPeriod = createSelector(
    selectParticipantsBills,
    (bills) =>
        bills.reduce((acc, curr) => {
            const endDate = curr.billingPeriodEnd;
            if (!acc[endDate]) {
                acc[endDate] = {};
            }
            const producerId = curr.producer.id;
            if (!acc[endDate][producerId]) {
                acc[endDate][producerId] = [];
            }
            acc[endDate][producerId].push(curr);
            return acc;
        }, {})
);

const selectProducers = createSelector(selectSelectedOperation, (operation) =>
    operation ? operation.producers : []
);

export const makeSelectConsumersProducersCombination = (
    producerId,
    consumerId
) =>
    createSelector(
        selectProducers,
        selectConsumers,
        selectParticipantsConfigs,
        (producers, consumers, configs) => {
            const combinations = [];
            producers
                .filter((producer) => !producerId || producer.id === producerId)
                .forEach((producer) => {
                    consumers
                        .filter(
                            (consumer) =>
                                !consumerId || consumer.id === consumerId
                        )
                        .forEach((consumer) => {
                            combinations.push({
                                producer,
                                consumer,
                            });
                        });
                });
            return combinations.sort((a, b) =>
                configs.find(
                    (c) =>
                        c.producers.includes(a.producer.id) &&
                        c.consumers.includes(a.consumer.id)
                )
                    ? -1
                    : 1
            );
        }
    );

export const makeSelectBillingConfigsChartData = (producerId, consumerId) =>
    createSelector(
        selectParticipantsConfigs,
        selectMapParticipantIdToActivationRange,
        makeSelectConsumersProducersCombination(producerId, consumerId),
        (configs, activationRanges, combinations) => {
            const colors = generateDistinctCoherentColors(configs.length);
            const sortedConfigs = configs.map((config, i) => ({
                ...config,
                firstAnniversary: findFirstAnniversary(config),
                color: colors[i],
            }));
            sortedConfigs.sort(
                ({ startDate: startDate1 }, { startDate: startDate2 }) =>
                    moment(startDate1).isAfter(startDate2) ? 1 : -1
            );
            const data = {};
            sortedConfigs.forEach((config) => {
                config.producers.forEach((producer) => {
                    config.consumers.forEach((consumer) => {
                        const index = combinations.findIndex(
                            (combination) =>
                                combination.consumer.id === consumer &&
                                combination.producer.id === producer
                        );
                        if (index > -1) {
                            const producerActivationRange =
                                activationRanges[producer];
                            const consumerActivationRange =
                                activationRanges[consumer];
                            const key = `${producer} - ${consumer}`;
                            const item = createItemFromConfig(
                                config,
                                index,
                                producerActivationRange,
                                consumerActivationRange
                            );
                            if (item) {
                                if (!data[key]) {
                                    data[key] = [item];
                                } else {
                                    const previousConfigEndDate = moment
                                        .min(
                                            moment(config.startDate).subtract(
                                                1,
                                                'days'
                                            ),
                                            moment(
                                                data[key][data[key].length - 1]
                                                    .value[2]
                                            )
                                        )
                                        .endOf('day')
                                        .format();

                                    data[key][data[key].length - 1].value[2] =
                                        previousConfigEndDate;

                                    data[key].push(item);
                                }
                            }
                        }
                    });
                });
            });
            return Object.values(data).flat();
        }
    );

const makeSelectBillsEditionDataChart = (producerId, consumerId) =>
    createSelector(
        makeSelectBillingConfigsChartData(producerId, consumerId),
        (data) => {
            const scatterData = [];
            data.forEach((item) => {
                const {
                    value: [index, startDate, endDate, config],
                } = item;
                const { firstAnniversary, period } = config;
                const date = firstAnniversary.clone();
                while (date.isSameOrBefore(moment(endDate))) {
                    if (date.isAfter(moment(startDate))) {
                        scatterData.push([date.format(), index]);
                    }
                    date.add(period, 'month');
                }
            });
            return scatterData;
        }
    );

export const makeSelectPastEditionDates = (producerId, consumerId) =>
    createSelector(
        makeSelectBillsEditionDataChart(producerId, consumerId),
        (data) => {
            const today = moment();
            return data.filter(([date]) =>
                moment(date).isSameOrBefore(today, 'days')
            );
        }
    );

export const makeSelectFutureEditionDates = (producerId, consumerId) =>
    createSelector(
        makeSelectBillsEditionDataChart(producerId, consumerId),
        (data) => {
            const today = moment();
            return data.filter(([date]) => moment(date).isAfter(today, 'days'));
        }
    );

export const findFirstAnniversary = (config) => {
    const { startDate, anniversary, period } = config;
    const date = moment(startDate).clone().add(1, 'days');
    while (date.diff(moment(anniversary), 'month', true) % period !== 0) {
        date.add(1, 'days');
    }
    return date;
};

const createItemFromConfig = (
    config,
    index,
    producerActivationRange,
    consumerActivationRange
) => {
    const { start: producerStart, end: producerEnd } = producerActivationRange;
    const { start: consumerStart, end: consumerEnd } = consumerActivationRange;
    const endDate = moment.min([
        moment().add(3, 'year'),
        ...(producerEnd ? [moment(producerEnd).subtract(1, 'days')] : []),
        ...(consumerEnd ? [moment(consumerEnd).subtract(1, 'days')] : []),
    ]);
    const startDate = moment.max([
        moment(config.startDate),
        ...(producerStart ? [moment(producerStart)] : []),
        ...(consumerStart ? [moment(consumerStart)] : []),
    ]);
    if (endDate.isBefore(startDate)) return null;

    return {
        value: [
            index,
            startDate.format(),
            endDate.format(),
            config,
            config.sellingPrice,
        ],
        itemStyle: {
            normal: {
                color: config.color,
            },
        },
    };
};

const generateDistinctCoherentColors = (count) => {
    const colors = [];

    const getConfigColor = (numberOfColors, index) => {
        const maxPercent = 100;
        const interval =
            numberOfColors > 1 ? (maxPercent * 2) / (numberOfColors - 1) : 0;
        return lightenDarkenColor('#1971c2', maxPercent - interval * index);
    };
    for (let i = 7; i < count; i++) {
        colors.push(getConfigColor(count, i));
    }
    return [
        '#1971c2',
        '#228be6',
        '#4dabf7',
        '#a5d8ff',
        '#e7f5ff',
        '#d0bfff',
        '#9775fa',
        ...colors,
    ];
};

export {
    selectParticipantsConfigs,
    selectEnabledParticipants,
    selectAnniversaryDate,
    selectParticipantsBillsGroupedByPeriod,
    selectDownloadLoading,
};
