import { createSelector } from 'reselect';
import { selectActiveConsumersSinceLastMensiversary } from './participantSelectors';
import {
    doesAlgoNeedParams,
    isAllocationAlgorithmCustomized,
} from '../../../../app-config';

const selectAllocationEnergy = (state) => state.energyAllocation;

const selectNewData = createSelector(
    selectAllocationEnergy,
    (energyAllocation) => energyAllocation.newData
);
const selectPersistedData = createSelector(
    selectAllocationEnergy,
    (energyAllocation) => energyAllocation.persistedData
);

export const selectNewAllocationAlgorithmType = createSelector(
    selectNewData,
    (newData) => newData.allocationAlgorithmType
);

export const selectNewParticipantsParameters = createSelector(
    selectNewData,
    (newData) => newData.participantsParameters
);

export const selectPersistedAllocationAlgorithmConfig = createSelector(
    selectPersistedData,
    (data) => data.allocationAlgorithmConfig
);

export const selectOperationAllocationAlgorithmId = createSelector(
    selectPersistedAllocationAlgorithmConfig,
    (config) => config?.id
);

export const selectOperationAllocationAlgorithmType = createSelector(
    selectPersistedAllocationAlgorithmConfig,
    (config) => config?.type
);

export const selectPersistedParticipantsParameters = createSelector(
    selectPersistedData,
    (newData) => newData.participantsParameters
);
export const selectParamsSum = createSelector(
    selectNewParticipantsParameters,
    (params) =>
        Object.values(params).reduce((acc, val) => {
            if (val === '' || isNaN(val)) {
                return acc;
            }
            return acc + Number(val);
        }, 0)
);

const selectIsNewAllocationValid = createSelector(
    selectNewAllocationAlgorithmType,
    selectNewParticipantsParameters,
    selectActiveConsumersSinceLastMensiversary,
    (newAlgorithmType, params, consumers) => {
        if (doesAlgoNeedParams(newAlgorithmType)) {
            return consumers.every(
                ({ id }) => params[id] !== '' && !isNaN(params[id])
            );
        }
        return !!newAlgorithmType;
    }
);

export const selectHasAllocationChanged = createSelector(
    selectPersistedAllocationAlgorithmConfig,
    selectPersistedParticipantsParameters,
    selectNewAllocationAlgorithmType,
    selectNewParticipantsParameters,
    (persistedConfig, persistedParams, newAlgorithmType, newParams) => {
        const hasConfigChanged = persistedConfig?.type !== newAlgorithmType;
        const hasParamsLengthChanged =
            Object.keys(persistedParams).length !==
            Object.keys(newParams).length;

        const hasParamsValuesChanged =
            JSON.stringify(persistedParams) !== JSON.stringify(newParams);
        return (
            hasConfigChanged || hasParamsLengthChanged || hasParamsValuesChanged
        );
    }
);

export const selectCanSubmitNewAllocation = createSelector(
    selectIsNewAllocationValid,
    selectHasAllocationChanged,
    (isValid, hasChanged) => {
        return isValid && hasChanged;
    }
);

export const selectHasAllocationTypeChanged = createSelector(
    selectPersistedAllocationAlgorithmConfig,
    selectNewAllocationAlgorithmType,
    (persistedConfig, newAlgorithmType) => {
        const hasConfigChanged = persistedConfig?.type !== newAlgorithmType;
        if (!hasConfigChanged) return false;
        if (
            isAllocationAlgorithmCustomized(persistedConfig?.type) &&
            isAllocationAlgorithmCustomized(newAlgorithmType)
        )
            return false;
        return true;
    }
);

export const selectPersistedOperationSeasonality = createSelector(
    selectPersistedData,
    (data) => data.operationSeasonality
);
