import { t } from 'i18next';

export const InfoStatus = () => {
    return {
        ERROR: t('enoapp.administrative.participants.infoStatusError'),
        HAS_BEEN_DELETED: t(
            'enoapp.administrative.participants.infoStatusDeleted'
        ),
        UP_TO_DATE: t('enoapp.administrative.participants.infoStatusUp'),
        DELETING: t('enoapp.administrative.participants.infoStatusDeleting'),
        CREATING: t('enoapp.administrative.participants.infoStatusCreating'),
        PROCESSING: t(
            'enoapp.administrative.participants.infoStatusProcessing'
        ),
    };
};

export const InfoStatusOrder = {
    [InfoStatus().CREATING]: 0,
    [InfoStatus().PROCESSING]: 1,
    [InfoStatus().UP_TO_DATE]: 2,
    [InfoStatus().DELETING]: 3,
    [InfoStatus().HAS_BEEN_DELETED]: 4,
    [InfoStatus().ERROR]: 5,
};
export default function infoStatus(participant) {
    const statusError = participant.dsoStatus === 'NO_TRAIL_ERR';
    const statusUpToDate = participant.dsoStatus === 'EN_VIGUEUR';
    const statusProcessing = !statusError && !statusUpToDate;
    const participantOut = dateHasExpired(
        participant.activationRange
            ? participant.activationRange[1]?.value
            : participant.enedisDetail?.activationRange[1]?.value
    );
    const participantIn = dateHasExpired(
        participant.activationRange
            ? participant.activationRange[0]?.value
            : participant.enedisDetail?.activationRange[0]?.value
    );

    function dateHasExpired(dateEnd) {
        if (!dateEnd) return false;
        else {
            return new Date(dateEnd) < Date.now();
        }
    }

    const actionError = participant.dsoAction === 'NO_TRAIL_ERR';
    const actionDelete = participant.dsoAction === 'SUPPRESSION';
    const actionCreate = participant.dsoAction === 'CREATION';
    const actionProcess = !actionDelete && !actionCreate;

    function tagMsg() {
        if (statusError || actionError) return InfoStatus().ERROR;
        if (participantOut) return InfoStatus().HAS_BEEN_DELETED;
        else if (statusUpToDate) {
            if (actionDelete) return InfoStatus().HAS_BEEN_DELETED;
            if (actionProcess) return InfoStatus().UP_TO_DATE;
        } else if (statusProcessing) {
            if (actionDelete) return InfoStatus().DELETING;
            if (actionCreate && participantIn) return InfoStatus().UP_TO_DATE;
            if (actionCreate && !participantIn) return InfoStatus().CREATING;
            if (actionProcess) return InfoStatus().PROCESSING;
        }
        return InfoStatus().ERROR;
    }

    return tagMsg();
}
