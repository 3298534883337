import { stringNumberWithSpaces } from './stringLib';
import Big from 'big.js';

function makeSize(number) {
    const bNumber = Big(number);
    switch (true) {
        case bNumber.lte(20):
            return 's';
        case bNumber.gt(20) && bNumber.lte(20000):
            return 'm';
        case bNumber.gt(20000) && bNumber.lte(1000000):
            return 'l';
        case bNumber.gt(1000000):
            return 'xl';
        default:
    }
}

export function formatNumber(number, basicUnit, forcedSize = false) {
    let fractionDigit;
    let unit;
    let coef;
    const size = forcedSize ? forcedSize : makeSize(number);
    switch (size) {
        case 's':
            fractionDigit = 1;
            unit = ` k${basicUnit}`;
            coef = 1;
            break;
        case 'm':
            fractionDigit = 0;
            unit = ` k${basicUnit}`;
            coef = 1;
            break;
        case 'l':
            fractionDigit = 1;
            unit = ` M${basicUnit}`;
            coef = 1000;
            break;
        case 'xl':
            fractionDigit = 0;
            unit = ` M${basicUnit}`;
            coef = 1000;
            break;
        default:
    }
    const formatted = Big(number).div(Big(coef));
    return basicUnit
        ? stringNumberWithSpaces(formatted.toFixed(fractionDigit)) + unit
        : formatted;
}

export function getDatasetFormatting(dataset) {
    const sortedValues = dataset
        .flatMap((item) => {
            const newItem = { ...item };
            delete newItem.timestamp;
            return Object.values(newItem);
        })
        .filter((elt) => elt > 0)
        .sort((a, b) => a > b);
    const length = sortedValues.length;
    const median =
        length > 0
            ? length % 2 !== 0
                ? Big(sortedValues[Math.floor(length / 2)])
                : Big(sortedValues[length / 2 - 1])
                      .plus(Big(sortedValues[length / 2]))
                      .div(2)
            : Big(0);
    const forcedSize = makeSize(median);
    let prefix;
    switch (forcedSize) {
        case 's':
        case 'm':
            prefix = 'k';
            break;
        case 'l':
        case 'xl':
            prefix = 'M';
            break;
        default:
    }
    const formatted = dataset.map((item) => {
        return Object.keys(item).reduce((acc, key) => {
            acc[key] =
                key === 'timestamp'
                    ? item[key]
                    : formatNumber(item[key], false, forcedSize);
            return acc;
        }, {});
    });
    return { forcedSize, prefix, formatted };
}
