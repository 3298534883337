import { createSelector } from 'reselect';
import moment from 'moment';
import Big from 'big.js';
import {
    selectACCAutoConsumptionByConsumers,
    selectACCAutoProductionByProducers,
    selectACCConsumptionByConsumers,
    selectACCGlobalAutoConsumption,
    selectACCGlobalConsumption,
    selectACCGlobalProduction,
    selectACCMaxGlobalAutoConsumption,
    selectACCProductionByProducers,
} from './ACCMeasurementSelectors';
import { selectActiveProsumers } from '../operationSelectors';
import {
    handleMetric,
    selectAutoConsumptionData,
    selectConsumptionData,
    selectExtConsumptionData,
    selectExtProductionData,
    selectMetricsData,
    selectProductionData,
    selectSurplusData,
} from './sharedSelectors';
import { t } from 'i18next';
const getLabel = (key) => t(`enoapp.technical.dashboard.legend.${key}`);

const selectGlobalExternalConsumption = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.extConsumptions ? metrics.extConsumptions : 0
);

const selectExternalConsumptions = createSelector(
    selectMetricsData,
    (metrics) => (metrics && metrics.extConsumptions ? metrics.extCons : 0)
);

// global Production

const selectACIGlobalProduction = createSelector(selectMetricsData, (metrics) =>
    metrics && metrics.autoExtProd
        ? metrics.autoExtProd.reduce((acc, { power }) => {
              acc = acc.plus(Big(power || 0));
              return acc;
          }, Big(0))
        : Big(0)
);

const selectGlobalIndividualAutoConsumption = createSelector(
    selectACCGlobalProduction,
    selectACIGlobalProduction,
    (ACCGlobalProduction, ACIGlobalProduction) =>
        ACIGlobalProduction.minus(ACCGlobalProduction)
);

const selectACIGlobalConsumption = createSelector(
    selectACCGlobalConsumption,
    selectGlobalExternalConsumption,
    selectGlobalIndividualAutoConsumption,
    (
        ACCGlobalConsumption,
        globalExternalConsumption,
        globalIndividualAutoConsumption
    ) =>
        ACCGlobalConsumption.add(globalExternalConsumption).add(
            globalIndividualAutoConsumption
        )
);

// max auto consumption

const selectACIMaxGlobalAutoConsumption = createSelector(
    selectACCMaxGlobalAutoConsumption,
    selectGlobalIndividualAutoConsumption,
    (ACCMaxGlobalAutoConsumption, globalIndividualAutoConsumption) =>
        ACCMaxGlobalAutoConsumption.add(globalIndividualAutoConsumption)
);

// global auto consumption

const selectACIGlobalAutoConsumption = createSelector(
    selectACCGlobalAutoConsumption,
    selectGlobalIndividualAutoConsumption,
    (ACCGlobalAutoConsumption, globalIndividualAutoConsumption) =>
        ACCGlobalAutoConsumption.add(globalIndividualAutoConsumption)
);

// production by producers
// TODO UNUSED?
const selectACIProductionByProducers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.autoExtProd ? handleMetric(metrics.autoExtProd) : []
);

// consumption by consumers
// ENEDIS specific case
// TODO UNUSED?
const mapProducersPrmToACCProduction = createSelector(
    selectACCProductionByProducers,
    (productionByProducers) =>
        productionByProducers.reduce((acc, curr) => {
            acc[curr.prm] = curr.value;
            return acc;
        }, {})
);

// ENEDIS specific case
// TODO UNUSED?
const mapConsumersPrmToName = createSelector(
    selectACCAutoConsumptionByConsumers,
    (autoConsumptionByConsumers) =>
        autoConsumptionByConsumers.reduce((acc, curr) => {
            acc[curr.prm] = curr.name;
            return acc;
        }, {})
);

// ENEDIS specific case
// TODO Unused ?
const selectIndividualAutoConsumptionByProsumers = createSelector(
    selectACIProductionByProducers,
    mapProducersPrmToACCProduction,
    mapConsumersPrmToName,
    selectActiveProsumers,
    (ACIProductionByProducers, prmToACCProduction, prmToName, prosumers) => {
        return ACIProductionByProducers.filter(({ id }) =>
            prosumers.includes(Number(id))
        ).map(({ name, value, prm, padt, id }) => {
            const prodValue = prmToACCProduction[prm];
            const consumerWithSamePrmName = prmToName[prm];
            const newValue = value - prodValue;
            return {
                id,
                name: `${consumerWithSamePrmName || name} *`,
                value: newValue,
                prm,
                padt,
            };
        });
    }
);

// ENEDIS specific case
// TODO Unused ?
const mapProsumersPrmToIndividualAutoConsumption = createSelector(
    selectIndividualAutoConsumptionByProsumers,
    (individualAutoConsumptionByProsumers) =>
        individualAutoConsumptionByProsumers.reduce((acc, curr) => {
            acc[curr.prm] = curr.value;
            return acc;
        }, {})
);

const selectGlobalACIDetailsAutoConsumptionForConsumption = createSelector(
    selectGlobalIndividualAutoConsumption,
    selectACCGlobalAutoConsumption,
    (globalIndividualAutoConsumption, ACCGlobalAutoConsumption) => [
        {
            name: getLabel('accSelfProduction'),
            value: parseFloat(ACCGlobalAutoConsumption.toFixed(1)),
        },
        {
            name: getLabel('producerSelfProduction'),
            value: parseFloat(globalIndividualAutoConsumption.toFixed(1)),
        },
    ]
);
const selectGlobalACIDetailsAutoConsumptionForProduction = createSelector(
    selectGlobalIndividualAutoConsumption,
    selectACCGlobalAutoConsumption,
    (globalIndividualAutoConsumption, ACCGlobalAutoConsumption) => [
        {
            name: getLabel('accSelfConsumption'),
            value: parseFloat(ACCGlobalAutoConsumption.toFixed(1)),
        },
        {
            name: getLabel('producerSelfConsumption'),
            value: parseFloat(globalIndividualAutoConsumption.toFixed(1)),
        },
    ]
);

const selectACIConsumptionByConsumers = createSelector(
    selectACCConsumptionByConsumers,
    mapProsumersPrmToIndividualAutoConsumption,
    (ACCConsumptionByConsumers, prosumersPrmToIndividualAutoConsumption) =>
        ACCConsumptionByConsumers.map(({ name, value, prm, id }) => {
            const individualAutoConsumption =
                prosumersPrmToIndividualAutoConsumption[prm];
            return {
                prm,
                id,
                name,
                value: value + (individualAutoConsumption || 0),
            };
        })
);

// autoProduction by producers

const selectACIAutoProductionByProducers = createSelector(
    selectACCAutoProductionByProducers,
    mapProsumersPrmToIndividualAutoConsumption,
    (ACCAutoProductionByProducers, prosumersPrmToIndividualAutoConsumption) =>
        ACCAutoProductionByProducers.map(({ prm, padt, name, value, id }) => {
            const individualAutoConsumption =
                prosumersPrmToIndividualAutoConsumption[prm];
            return {
                id,
                name,
                value: value + (individualAutoConsumption || 0),
                prm,
                padt,
            };
        })
);

// GlobalConsumptionChartData

const selectGlobalACIConsumptionChartData = createSelector(
    selectConsumptionData,
    selectProductionData,
    selectAutoConsumptionData,
    selectSurplusData,
    selectExtProductionData,
    selectExtConsumptionData,
    (
        consumptionData,
        productionData,
        autoConsumptionData,
        surplusData,
        extProductionData,
        extConsumptionData
    ) =>
        consumptionData &&
        productionData &&
        autoConsumptionData &&
        surplusData &&
        extProductionData &&
        extConsumptionData
            ? consumptionData.map((curr, idx) => {
                  const consumption = Big(curr.power || 0);
                  const prod = Big(productionData[idx]?.power || 0);
                  const prodExt = Big(extProductionData[idx]?.power || 0);
                  const individualAutoConsumption = prodExt.minus(prod);
                  const selfConsumption = Big(
                      autoConsumptionData[idx]?.power || 0
                  );
                  const excess = Big(surplusData[idx]?.power || 0).times(-1);
                  const extConsumption = Big(
                      extConsumptionData[idx]?.power || 0
                  );
                  const extConsumptionWithACI =
                      individualAutoConsumption.add(extConsumption);
                  return {
                      timestamp: moment(curr.time).valueOf(),
                      consumption: parseFloat(
                          consumption.add(extConsumptionWithACI).div(1000)
                      ),
                      selfConsumption: parseFloat(
                          individualAutoConsumption
                              .add(selfConsumption)
                              .div(1000)
                      ),
                      excess: parseFloat(excess.div(1000)),
                  };
              })
            : []
);

const selectGlobalACIDetailsConsumptionChartData = createSelector(
    selectConsumptionData,
    selectProductionData,
    selectAutoConsumptionData,
    selectSurplusData,
    selectExtProductionData,
    selectExtConsumptionData,
    (
        consumptionData,
        productionData,
        autoConsumptionData,
        surplusData,
        extProductionData,
        extConsumptionData
    ) =>
        consumptionData &&
        productionData &&
        autoConsumptionData &&
        surplusData &&
        extProductionData &&
        extConsumptionData
            ? consumptionData.map((curr, idx) => {
                  const consumptionOfLocalConsumers = Big(curr.power || 0);
                  const prod = Big(productionData[idx]?.power || 0);
                  const prodExt = Big(extProductionData[idx]?.power || 0);
                  const individualAutoConsumption = prodExt.minus(prod);
                  const selfConsumption = Big(
                      autoConsumptionData[idx]?.power || 0
                  );
                  const excess = Big(surplusData[idx]?.power || 0).times(-1);
                  const extConsumption = Big(
                      extConsumptionData[idx]?.power || 0
                  );
                  const extConsumptionWithACI =
                      individualAutoConsumption.add(extConsumption);
                  return {
                      timestamp: moment(curr.time).valueOf(),
                      collectiveSelfConsumption: parseFloat(
                          selfConsumption.div(1000)
                      ),
                      consumption: parseFloat(
                          consumptionOfLocalConsumers
                              .add(extConsumptionWithACI)
                              .div(1000)
                      ),
                      selfConsumption: parseFloat(
                          individualAutoConsumption
                              .add(selfConsumption)
                              .div(1000)
                      ),
                      consumersCollectiveConsumption: parseFloat(
                          consumptionOfLocalConsumers.div(1000)
                      ),
                      individualSelfConsumption: parseFloat(
                          individualAutoConsumption.div(1000)
                      ),
                      prosumersConsumptionWithACI: parseFloat(
                          extConsumption.div(1000)
                      ),
                      consumersSupplierConsumption: parseFloat(
                          consumptionOfLocalConsumers
                              .minus(selfConsumption)
                              .div(1000)
                      ),
                      producersSupplierConsumption: parseFloat(
                          extConsumption.div(1000)
                      ),
                      excess: parseFloat(excess.div(1000)),
                  };
              })
            : []
);

// globalProductionChartData

const selectGlobalACIProductionChartData = createSelector(
    selectProductionData,
    selectAutoConsumptionData,
    selectExtProductionData,
    (productionData, autoConsumptionData, extProductionData) =>
        productionData && autoConsumptionData && extProductionData
            ? productionData.map((curr, idx) => {
                  const production = Big(curr.power || 0);
                  const selfConsumption = Big(
                      autoConsumptionData[idx]?.power || 0
                  );
                  const prodExt = Big(extProductionData[idx]?.power || 0);
                  const individualAutoConsumption = prodExt.minus(production);

                  return {
                      timestamp: moment(curr.time).valueOf(),
                      production: parseFloat(prodExt.div(1000)),
                      selfConsumption: parseFloat(
                          individualAutoConsumption
                              .add(selfConsumption)
                              .div(1000)
                      ),
                  };
              })
            : []
);

const selectGlobalACIDetailsProductionChartData = createSelector(
    selectProductionData,
    selectAutoConsumptionData,
    selectExtProductionData,
    (productionData, autoConsumptionData, extProductionData) =>
        productionData && autoConsumptionData && extProductionData
            ? productionData.map((curr, idx) => {
                  const production = Big(curr.power || 0);
                  const selfConsumption = Big(
                      autoConsumptionData[idx]?.power || 0
                  );
                  const prodExt = Big(extProductionData[idx]?.power || 0);
                  const individualAutoConsumption = prodExt.minus(production);

                  return {
                      timestamp: moment(curr.time).valueOf(),
                      collectiveSelfConsumption: parseFloat(
                          selfConsumption.div(1000)
                      ),
                      production: parseFloat(prodExt.div(1000)),
                      selfConsumption: parseFloat(
                          individualAutoConsumption
                              .add(selfConsumption)
                              .div(1000)
                      ),
                      individualSelfConsumption: parseFloat(
                          individualAutoConsumption.div(1000)
                      ),
                  };
              })
            : []
);

const selectACIGesKpi = createSelector(selectMetricsData, (metrics) =>
    metrics && metrics.ACIGesKpi ? metrics.ACIGesKpi : 0
);

export {
    selectACIGlobalProduction,
    selectACIGlobalConsumption,
    selectACIMaxGlobalAutoConsumption,
    selectACIGlobalAutoConsumption,
    selectGlobalACIDetailsAutoConsumptionForConsumption,
    selectGlobalACIDetailsAutoConsumptionForProduction,
    selectACIProductionByProducers,
    selectACIConsumptionByConsumers,
    selectACIAutoProductionByProducers,
    selectGlobalACIConsumptionChartData,
    selectGlobalACIDetailsConsumptionChartData,
    selectGlobalACIProductionChartData,
    selectGlobalACIDetailsProductionChartData,
    selectGlobalExternalConsumption,
    selectACIGesKpi,
    selectGlobalIndividualAutoConsumption,
    selectIndividualAutoConsumptionByProsumers,
    selectExternalConsumptions,
};
