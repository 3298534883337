import client from '../../libs/client';

export default class APINavigationGateway {
    getAppMap = async () => {
        const { data: appMap } = await client.get('/appmap');
        return {
            enoconso: appMap.enoconso,
            enoapp: appMap.enoapp,
            enoroot: appMap.root,
        };
    };
}
