import { INIT_CRUMBS, INIT_ROUTES, SET_CRUMBS, SET_ROUTES } from './constants';
import { combineReducers } from 'redux';

const initialState = {
    crumbs: [],
    routes: {
        history: null,
        match: null,
    },
};

function routesReducer(state = initialState.routes, action) {
    switch (action.type) {
        case SET_ROUTES: {
            return { ...state, ...action.routes };
        }
        case INIT_ROUTES: {
            return {
                history: null,
                match: null,
            };
        }
        default:
            return state;
    }
}

function crumbsReducer(state = initialState.crumbs, action) {
    switch (action.type) {
        case SET_CRUMBS: {
            return [...action.crumbs];
        }
        case INIT_CRUMBS: {
            return [];
        }
        default:
            return state;
    }
}

export default combineReducers({
    routes: routesReducer,
    crumbs: crumbsReducer,
});
