import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
    setAccountInfo,
    setAccountOperationBillings,
    setError,
} from '../../redux/actions';
import {
    GET_ACCOUNT_INFO,
    GET_ACCOUNT_OPERATION_BILLINGS,
} from '../../redux/reducers/constants';
import { selectUser } from '../../redux/selectors/authenticationSelectors';

function* getAccountInfo() {
    try {
        const { id } = yield select(selectUser);
        const accountGateway = yield getContext('accountGateway');
        const accountInfo = yield call(accountGateway.getAccountInfo, id);
        yield put(setAccountInfo(accountInfo));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* getAccountOperationBillings() {
    try {
        const { id } = yield select(selectUser);
        const accountGateway = yield getContext('accountGateway');
        const accountOperationBillings = yield call(
            accountGateway.getAccountOperationBillings,
            id
        );
        yield put(setAccountOperationBillings(accountOperationBillings));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

export function* getAccountInfoSaga() {
    yield takeLatest(GET_ACCOUNT_INFO, getAccountInfo);
}

export function* getAccountOperationBillingsSaga() {
    yield takeLatest(
        GET_ACCOUNT_OPERATION_BILLINGS,
        getAccountOperationBillings
    );
}

const accountSagas = [getAccountInfoSaga, getAccountOperationBillingsSaga];

export default accountSagas;
