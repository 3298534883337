import { createSelector } from 'reselect';
import moment from 'moment';
import Big from 'big.js';
import {
    handleMetric,
    selectAutoConsumptionData,
    selectConsumptionData,
    selectMetricsData,
    selectProductionData,
    selectSurplusData,
} from './sharedSelectors';

// global Production
const selectACCGlobalProduction = createSelector(selectMetricsData, (metrics) =>
    metrics && metrics.globalProduction ? Big(metrics.globalProduction) : Big(0)
);

// global consumption
const selectACCGlobalConsumption = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.globalConsumption
            ? Big(metrics.globalConsumption)
            : Big(0)
);

// max auto consumption
const selectACCMaxGlobalAutoConsumption = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.maxSelfConsumption
            ? Big(metrics.maxSelfConsumption)
            : Big(0)
);

// global auto consumption
const selectACCGlobalAutoConsumption = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.selfProductionValue
            ? Big(metrics.selfProductionValue)
            : Big(0)
);

// production by producers
// TODO UNUSED?
const selectACCProductionByProducers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.totalProd ? handleMetric(metrics.totalProd) : []
);

// consumption by consumers
// TODO UNUSED?
const selectACCConsumptionByConsumers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.totalCons ? handleMetric(metrics.totalCons) : []
);

// autoProduction by producers
// TODO UNUSED?
const selectACCAutoProductionByProducers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.localProd ? handleMetric(metrics.localProd) : []
);

// autoConsumption by consumers
// TODO UNUSED?
const selectACCAutoConsumptionByConsumers = createSelector(
    selectMetricsData,
    (metrics) =>
        metrics && metrics.localCons ? handleMetric(metrics.localCons) : []
);

// GlobalConsumptionChartData
const selectGlobalACCConsumptionChartData = createSelector(
    selectConsumptionData,
    selectAutoConsumptionData,
    selectSurplusData,
    (consumptionData, autoConsumptionData, surplusData) =>
        consumptionData && autoConsumptionData && surplusData
            ? consumptionData.map((curr, idx) => {
                  const consumption = Big(curr.power || 0);
                  const selfConsumption = Big(
                      autoConsumptionData[idx]?.power || 0
                  );
                  const excess = Big(surplusData[idx]?.power || 0).times(-1);

                  return {
                      timestamp: moment(curr.time).valueOf(),
                      consumption: parseFloat(consumption.div(1000)),
                      selfConsumption: parseFloat(selfConsumption.div(1000)),
                      excess: parseFloat(excess.div(1000)),
                  };
              })
            : []
);

// globalProductionChartData
const selectGlobalACCProductionChartData = createSelector(
    selectProductionData,
    selectAutoConsumptionData,
    (productionData, autoConsumptionData) =>
        productionData && autoConsumptionData
            ? productionData.map((curr, idx) => {
                  const production = Big(curr.power || 0);
                  const autoConsumption = Big(
                      autoConsumptionData[idx]?.power || 0
                  );

                  return {
                      timestamp: moment(curr.time).valueOf(),
                      production: parseFloat(production.div(1000)),
                      selfConsumption: parseFloat(autoConsumption.div(1000)),
                  };
              })
            : []
);

const selectACCGesKpi = createSelector(selectMetricsData, (metrics) =>
    metrics && metrics.ACCGesKpi ? metrics.ACCGesKpi : 0
);

export {
    selectACCGlobalProduction,
    selectACCGlobalConsumption,
    selectACCMaxGlobalAutoConsumption,
    selectACCGlobalAutoConsumption,
    selectACCProductionByProducers,
    selectACCConsumptionByConsumers,
    selectACCAutoProductionByProducers,
    selectACCAutoConsumptionByConsumers,
    selectGlobalACCConsumptionChartData,
    selectGlobalACCProductionChartData,
    selectACCGesKpi,
};
