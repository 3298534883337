import { MuiThemeProvider } from '@material-ui/core/styles';
import MuiTheme from '../../../../theme';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTheme } from '../../../corelogic/redux/selectors/operationSelectors';

const CustomizedThemeProvider = ({ children, theme }) => {
    return (
        <MuiThemeProvider
            theme={MuiTheme(theme && theme.primary, theme && theme.secondary)}
        >
            {children}
        </MuiThemeProvider>
    );
};

const mapStateToProps = createStructuredSelector({
    theme: selectTheme,
});

export default connect(mapStateToProps)(CustomizedThemeProvider);
