import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
    applyConfig,
    initConsumptionAndEconomy,
    setConsumptionAndEconomy,
    setConsumptionAndEconomyLoading,
    setError,
    setTRVPrice,
} from '../../redux/actions';
import {
    GET_CONSUMPTION_AND_ECONOMY,
    GET_PRICES_CONFIGS,
    GET_TRV_PRICE,
} from '../../redux/reducers/constants';
import {
    selectConfiguredConsumers,
    selectGlobalForm,
} from '../../redux/selectors/billCheckSelector';
import { selectConsumers } from '../../redux/selectors/participantSelectors';

function* getConsumptionAndEconomyByConsumer() {
    try {
        yield put(initConsumptionAndEconomy());
        yield put(setConsumptionAndEconomyLoading(true));
        const { startDate, endDate, ...participants } = yield select(
            selectGlobalForm
        );
        const configuredConsumers = yield select(selectConfiguredConsumers);
        const billCheckGateway = yield getContext('billCheckGateway');
        const participantsIds = configuredConsumers.map(({ id }) => id);
        for (let i = 0; i < participantsIds.length; i++) {
            const id = participantsIds[i];
            const {
                consumerType,
                contractType,
                basekWhPrice,
                peakkWhPrice,
                offPeakkWhPrice,
                summerPeakkWhPrice,
                summerOffPeakkWhPrice,
                winterPeakkWhPrice,
                winterOffPeakkWhPrice,
                peakHourStart,
                offPeakHourStart,
                isOnTRV,
            } = participants[id];
            const economy = yield call(
                billCheckGateway.getEconomyByConsumerIdAndPeriod,
                id,
                startDate.toISOString(),
                endDate.toISOString(),
                consumerType,
                contractType,
                isOnTRV,
                basekWhPrice,
                peakkWhPrice,
                offPeakkWhPrice,
                summerPeakkWhPrice,
                summerOffPeakkWhPrice,
                winterPeakkWhPrice,
                winterOffPeakkWhPrice,
                peakHourStart,
                offPeakHourStart
            );
            const consumption = yield call(
                billCheckGateway.getConsumptionByConsumerIdAndPeriod,
                id,
                startDate.toISOString(),
                endDate.toISOString()
            );
            const economyAndConsumption = {
                id,
                economy,
                autoconsumption: consumption.selfConsumed,
                consumption: consumption.globalConsumption,
            };
            yield put(setConsumptionAndEconomy(economyAndConsumption));
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
    yield put(setConsumptionAndEconomyLoading(false));
}

function* getPricesConfigs() {
    try {
        const billCheckGateway = yield getContext('billCheckGateway');
        const consumers = yield select(selectConsumers);
        const configMappings = yield call(
            billCheckGateway.getPriceConfigs,
            consumers.map(({ id }) => id)
        );
        for (let idx = 0; idx < configMappings.length; idx++) {
            const configMapping = configMappings[idx];
            const consumerId = configMapping.consumerId;
            if (configMapping.config) {
                yield put(applyConfig([consumerId], configMapping.config));
            }
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getTRVPrice() {
    try {
        const billCheckGateway = yield getContext('billCheckGateway');
        const trvPrice = yield call(billCheckGateway.getTRVPrice);
        yield put(setTRVPrice(trvPrice));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

export function* getConsumptionAndEconomyByConsumerSaga() {
    yield takeLatest(
        GET_CONSUMPTION_AND_ECONOMY,
        getConsumptionAndEconomyByConsumer
    );
}

export function* getPricesConfigsSaga() {
    yield takeLatest(GET_PRICES_CONFIGS, getPricesConfigs);
}

export function* getTRVPriceSaga() {
    yield takeLatest(GET_TRV_PRICE, getTRVPrice);
}
const billCheckSagas = [
    getConsumptionAndEconomyByConsumerSaga,
    getPricesConfigsSaga,
    getTRVPriceSaga,
];
export default billCheckSagas;
