import {
    INIT_OPERATIONS,
    INIT_SELECTED_OPERATION,
    INIT_SORTING,
    SET_OPERATIONS,
    SET_PARTICIPANT_TYPE,
    SET_SEARCH,
    SET_SELECTED_OPERATION,
    SET_SORTING,
} from './constants';
import { columnTypes } from '../../../adapters/primary/ui/enoapp/app-config';

const initialState = {
    operations: [],
    selectedOperation: null,
    filter: {
        participantType: 'all',
        search: '',
        sorting: {
            order: 'asc',
            orderBy: 'folder_status',
            type: columnTypes.ENEDIS,
        },
    },
};

function operationReducer(state = initialState, action) {
    switch (action.type) {
        case SET_OPERATIONS: {
            return { ...state, operations: action.operations };
        }

        case INIT_OPERATIONS: {
            return { ...state, operations: [] };
        }

        case SET_SELECTED_OPERATION: {
            return {
                ...state,
                selectedOperation: { ...action.selectedOperation },
            };
        }

        case INIT_SELECTED_OPERATION: {
            return { ...state, selectedOperation: null };
        }

        case SET_SEARCH: {
            return {
                ...state,
                filter: { ...state.filter, search: action.search },
            };
        }

        case SET_SORTING: {
            return {
                ...state,
                filter: { ...state.filter, sorting: action.sorting },
            };
        }
        case INIT_SORTING: {
            return {
                ...state,
            };
        }

        case SET_PARTICIPANT_TYPE: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    participantType: action.participantType,
                },
            };
        }

        default:
            return state;
    }
}

export default operationReducer;
