import client from '../../libs/client';
import axios from 'axios';

export default class ParticipantGateway {
    error;

    setError = (error) => {
        this.error = error;
    };

    uploadBillingLogo = async (participant, formData) => {
        const res = await client.post(
            `participants/${participant.id}/upload-billing-logo`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return res.data;
    };

    updateParticipant = async (
        participant,
        newParticipant,
        billingLogoPath
    ) => {
        if (newParticipant.enedisDetail) {
            delete newParticipant.enedisDetail.activationRange;
            delete newParticipant.enedisDetail.updatedAt;
        }

        if (newParticipant.billingDetail) {
            delete newParticipant.billingDetail.updatedAt;
        }
        if (participant.energyType === 'producer') {
            newParticipant.billingDetail.billingLogo = billingLogoPath;
        }
        // Encode url
        let url = encodeURI(`participants/${participant.id}`);
        url = url.replace(/#/g, '%23');
        await client.put(url, newParticipant);
    };

    getPresignedBillingLogo = async (participant) => {
        let res;
        if (participant && participant.billingDetail.billingLogo) {
            const {
                data: { url },
            } = await client.get(
                `/participants/${participant.id}/presigned-billing-logo`
            );
            const { data: logo } = await axios(url, {
                method: 'GET',
                responseType: 'blob',
            });
            res = URL.createObjectURL(logo);
        }
        return res;
    };

    importParticipants = async (operation, file) => {
        let formData = new FormData();
        formData.append('file', file);
        const res = await client.post(
            `operations/${operation.id}/participants/import`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return res.data;
    };
}
