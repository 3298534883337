import { INIT_ACCOUNT_INFO, SET_ACCOUNT_INFO } from './constants';

const initialState = {
    userAccount: {},
    organizers: [],
};

function accountReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_ACCOUNT_INFO: {
            return {};
        }
        case SET_ACCOUNT_INFO: {
            return { ...action.accountInfo };
        }
        default:
            return state;
    }
}

export default accountReducer;
