import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = async (url, options = {}) => {
    if (url.includes('participant-bills')) {
        url += (url.includes('?') ? '&' : '?') + 'billType=participant';
    }
    url = url.replace('cgu-approvals', 'cgu/approvals');
    url = url.replace('participant-bills', 'bills');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};
export default simpleRestProvider(
    process.env.REACT_APP_API_URL + '/admin',
    httpClient
);
