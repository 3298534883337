import { t } from 'i18next';

const AccountStatus = () => {
    return {
        ACTIVE: t('enoapp.administrative.participants.accountStatusActive'),
        INITIALIZED: t(
            'enoapp.administrative.participants.accountStatusInactive'
        ),
        UNINITIALIZED: t(
            'enoapp.administrative.participants.accountStatusNone'
        ),
        ERROR: t('enoapp.administrative.participants.accountStatusError'),
    };
};

function accountStatus(participant) {
    const unInitialized = !participant.user;
    const initializedButInactive =
        participant.user && !participant.user.initialized;
    const active = participant.user && participant.user.initialized;

    function tagMsg() {
        if (unInitialized) {
            return AccountStatus().UNINITIALIZED;
        } else if (initializedButInactive) {
            return AccountStatus().INITIALIZED;
        } else if (active) return AccountStatus().ACTIVE;
        else return AccountStatus().ERROR;
    }

    return tagMsg();
}

export { AccountStatus, accountStatus };
