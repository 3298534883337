import {
    INIT_MEASUREMENT,
    SET_CO2_METRICS_DATA,
    SET_CO2_METRICS_LOADING,
    SET_METRICS_DATA,
    SET_METRICS_LOADING,
} from '../constants';
import { MetricNaturesEnum } from '../../../../../app-config';

const initialState = {
    metrics: {
        loading: false,
        data: null,
    },
    ...Object.values(MetricNaturesEnum).reduce((acc, curr) => {
        acc[curr] = { loading: false, data: null };
        return acc;
    }, {}),
};

export function metricsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CO2_METRICS_DATA: {
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    data: action.metrics,
                },
            };
        }
        case SET_CO2_METRICS_LOADING: {
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    loading: action.loading,
                },
            };
        }
        case SET_METRICS_DATA: {
            return {
                ...state,
                [action.metricNature]: {
                    ...state[action.metricNature],
                    data: action.metrics,
                },
            };
        }
        case SET_METRICS_LOADING: {
            return {
                ...state,
                [action.metricNature]: {
                    ...state[action.metricNature],
                    loading: action.loading,
                },
            };
        }
        case INIT_MEASUREMENT: {
            return { ...initialState };
        }

        default:
            return state;
    }
}
