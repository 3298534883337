import { createSelector } from 'reselect';
import { selectSelectedOperation } from './operationSelectors';
import { consumerTypesENUM } from '../../../../app-config';
import moment from 'moment/moment';

const selectPresignedBillingLogo = (state) =>
    state.participant.presignedBillingLogo;

const selectConsumers = createSelector(selectSelectedOperation, (operation) =>
    operation ? operation.consumers : []
);

export const selectProducers = createSelector(
    selectSelectedOperation,
    (operation) => (operation ? operation.producers : [])
);

const selectParticipants = createSelector(
    selectConsumers,
    selectProducers,
    (consumers, producers) => [...consumers, ...producers]
);

const makeSelectConsumerEntityType = (participantId) =>
    createSelector(selectConsumers, (consumers) => {
        const consumer = consumers.find(({ id }) => id === participantId);
        const entityType = consumer.enedisDetail?.entityType;
        if (entityType?.startsWith('Professionnel')) {
            return consumerTypesENUM.PROFESSIONAL;
        }
        if (entityType?.startsWith('Particulier')) {
            return consumerTypesENUM.INDIVIDUAL;
        }
        if (consumer.billingDetail?.siret) {
            return consumerTypesENUM.PROFESSIONAL;
        }
        return consumerTypesENUM.INDIVIDUAL;
    });

const selectOperationAnniversaryDate = createSelector(
    selectSelectedOperation,
    // set it by default to 2020-01-01 in order to get set the mensiversary date to 01-01
    (operation) => operation?.anniversaryDate || '2020-01-01'
);

const selectOperationLastMensiversaryDate = createSelector(
    selectOperationAnniversaryDate,
    (anniversaryDate) => {
        const anniversaryDateMoment = moment(anniversaryDate);
        const lastMensiversaryDate = anniversaryDateMoment
            .clone()
            .year(moment().year())
            .month(moment().month());
        return lastMensiversaryDate.format('YYYY-MM-DD');
    }
);

export const selectActiveConsumersSinceLastMensiversary = createSelector(
    selectConsumers,
    selectOperationLastMensiversaryDate,
    (consumers, lastMensiversary) => {
        return consumers.filter((consumer) => {
            const activationRangeEnd = consumer.activationRange[1]?.value;
            if (!activationRangeEnd) {
                return true;
            }
            return moment(activationRangeEnd)
                .subtract(1, 'day')
                .isSameOrAfter(moment(lastMensiversary), 'day');
        });
    }
);

export const selectActiveProducersSinceLastMensiversary = createSelector(
    selectProducers,
    selectOperationLastMensiversaryDate,
    (producers, lastMensiversary) => {
        return producers.filter((producer) => {
            const activationRangeEnd = producer.activationRange[1]?.value;
            if (!activationRangeEnd) {
                return true;
            }
            return moment(activationRangeEnd)
                .subtract(1, 'day')
                .isSameOrAfter(moment(lastMensiversary), 'day');
        });
    }
);

export const selectMapParticipantIdToBillingInfo = createSelector(
    selectParticipants,
    (participants) => {
        return participants.reduce((acc, part) => {
            acc[part.id] = {
                name: part.billingDetail?.name,
                treasury: part.billingDetail?.treasury,
            };
            return acc;
        }, {});
    }
);

export const selectMapParticipantIdToActivationRange = createSelector(
    selectParticipants,
    (participants) => {
        return participants.reduce((acc, part) => {
            acc[part.id] = {
                start: part.activationRange[0]?.value,
                end: part.activationRange[1]?.value,
            };
            return acc;
        }, {});
    }
);

export const makeSelectProducer = (producerId) =>
    createSelector(selectProducers, (producers) => {
        const producer = producers.find(({ id }) => id === producerId);
        return producer;
    });
export const makeSelectProducerName = (producerId) =>
    createSelector(makeSelectProducer(producerId), (producer) => {
        if (!producer) {
            return null;
        }
        return (
            producer.billingDetail.name ||
            producer.enedisDetail.name ||
            producer.enedisDetail.prm
        );
    });

export const makeSelectProducerPrm = (producerId) =>
    createSelector(makeSelectProducer(producerId), (producer) => {
        if (!producer) {
            return null;
        }
        return producer.enedisDetail.prm;
    });

export const makeSelectConsumer = (consumerId) =>
    createSelector(selectConsumers, (consumers) => {
        const consumer = consumers.find(({ id }) => id === consumerId);
        return consumer;
    });
export const makeSelectConsumerName = (consumerId) =>
    createSelector(makeSelectConsumer(consumerId), (consumer) => {
        if (!consumer) {
            return null;
        }
        return (
            consumer.billingDetail.name ||
            consumer.enedisDetail.name ||
            consumer.enedisDetail.prm
        );
    });

export {
    selectPresignedBillingLogo,
    selectConsumers,
    makeSelectConsumerEntityType,
};
