import React from 'react';
import errorImg from './../../../enoapp/img/error.svg';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectRoutes } from '../../../../../../corelogic/redux/selectors/routeSelector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '96vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '64px 32px',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '32px',
    },
    btnSecondary: {
        display: 'flex',
        padding: '16px 24px',
        color: '#152463',
        fontWeight: 700,
        border: '2px solid #152463 !important',
        '&:hover': {
            opacity: '90%',
        },
    },
    btnPrimary: {
        display: 'flex',
        padding: '16px 24px',
        background: '#152463',
        color: '#FFFFFF',
        fontWeight: 700,
        '&:hover': {
            background: '#152463',
            opacity: '90%',
        },
    },
    text: {
        fontWeight: 400,
        fontSize: '16px',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.7)',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '12px',
    },
}));

const GlobalErrorsPage = (props) => {
    const classes = useStyles();
    const { history } = useSelector(selectRoutes);

    function goToHomePage() {
        history.push('/');
    }

    function refreshPage() {
        window.location.reload(false);
    }

    return (
        <div className={classes.container}>
            <img src={errorImg} width="15%" alt="ErrorNotFoundImage" />
            <Typography variant={'h3'} component={'h3'}>
                Un problème est survenu
            </Typography>
            <div className={classes.textContainer}>
                <span className={classes.text}>
                    Nous n’avons pas réussi à afficher correctement cette page.
                    Vous pouvez rafraichir la page et ré-essayer.
                </span>
                <span className={classes.text}>
                    Si le problème persiste, vous pouvez{' '}
                    <a href="mailto: support@enogrid.com?subject=[EnoPower] - Page d'erreur ">
                        contacter le support
                    </a>{' '}
                    et nous expliquer comment reproduire le problème.
                </span>
            </div>
            <div className={classes.btnContainer}>
                <Button
                    className={classes.btnSecondary}
                    onClick={() => refreshPage()}
                >
                    Rafraichir la page
                </Button>
                <Button
                    className={classes.btnPrimary}
                    onClick={() => goToHomePage()}
                >
                    Retour à l'accueil
                </Button>
            </div>
        </div>
    );
};

export default GlobalErrorsPage;
