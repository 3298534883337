import { SET_APPMAP } from './constants';
import { combineReducers } from 'redux';

function appMapReducer(state = {}, action) {
    switch (action.type) {
        case SET_APPMAP: {
            return action.appMap;
        }
        default:
            return state;
    }
}

export default combineReducers({
    appMap: appMapReducer,
});
