import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { routerMiddleware } from 'connected-react-router';

export default function configureStore({
    authProvider,
    dataProvider,
    history,
    sagaContext,
}) {
    const sagaMiddleware = createSagaMiddleware({ context: sagaContext });
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);
    const store = createStore(rootReducer(history), composedEnhancers);
    sagaMiddleware.run(rootSaga(dataProvider, authProvider));
    return store;
}
