export function lightenDarkenColor(col, percent) {
    col = col.slice(1);

    let num = parseInt(col, 16);

    let r = (num >> 16) + percent;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + percent;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + percent;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return '#' + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
}

export function colorIsTooBright(color, limit) {
    var c = color.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma > limit;
}
