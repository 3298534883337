import React from 'react';
import GlobalErrorsPage from './Components/Errors/GlobalErrorsPage';
import { withSnackbar } from 'notistack';
import { createStructuredSelector } from 'reselect';
import { selectError } from '../../../../corelogic/redux/selectors/errorSelector';
import { connect } from 'react-redux';
import { initError, setRoutes } from '../../../../corelogic/redux/actions';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidMount() {
        const { history, match } = this.props;
        this.props.setRoutes({ history: history, match: match });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { error, initError } = this.props;
        if (
            prevProps.error !== error &&
            (error.message || (error.status && error?.status !== 401))
        ) {
            const message = (
                <div
                    style={{
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {error.message ||
                        "Une erreur s'est produite. Veuillez réessayer"}
                </div>
            );
            this.props.enqueueSnackbar(message, {
                autoHideDuration: 7000,
                persist: error?.persist,
                variant: error.variant || 'error',
            });
            initError();
        }
    }

    async componentDidCatch(error, errorInfo) {
        this.props.enqueueSnackbar(
            "Si le problème persiste n'hésitez pas à nous contacter",
            {
                autoHideDuration: 7000,
                variant: 'error',
            }
        );
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.;
        console.error('error', error);
    }

    render() {
        return this.state.hasError
            ? this.props.errorComponent || <GlobalErrorsPage />
            : this.props.children;
    }
}

const mapStateToProps = createStructuredSelector({
    error: selectError,
});

const mapDispatchToProps = {
    initError,
    setRoutes,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSnackbar(withRouter(ErrorBoundary)));
