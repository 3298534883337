import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { setError, setAppMap } from '../../redux/actions';
import { GET_APPMAP } from '../../redux/reducers/constants';

function* getAppMap(action) {
    try {
        const navigationGateway = yield getContext('navigationGateway');
        const appMap = yield call(navigationGateway.getAppMap);
        yield put(setAppMap(appMap));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
    }
}

export function* getAppMapSaga() {
    yield takeLatest(GET_APPMAP, getAppMap);
}

const navigationSagas = [getAppMapSaga];

export default navigationSagas;
