import { SET_DOWNLOAD_LOADING, SET_PARTICIPANTS_BILLS } from './constants';

const initialState = {
    participants: [],
    excess: [],
    downloadLoading: false,
};

function billingReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PARTICIPANTS_BILLS: {
            return { ...state, participants: [...action.bills] };
        }
        case SET_DOWNLOAD_LOADING: {
            return { ...state, downloadLoading: action.loading };
        }
        default:
            return state;
    }
}

export default billingReducer;
