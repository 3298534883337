import client from '../../libs/client';
import { MetricNaturesEnum } from '../../../../../app-config';

export default class BillCheckGateway {
    getEconomyByConsumerIdAndPeriod = async (
        id,
        startDate,
        endDate,
        consumerType,
        contractType,
        isOnTRV,
        basekWhPrice,
        peakkWhPrice,
        offPeakkWhPrice,
        summerPeakkWhPrice,
        summerOffPeakkWhPrice,
        winterPeakkWhPrice,
        winterOffPeakkWhPrice,
        peakStart,
        offPeakStart
    ) => {
        try {
            const { data } = await client.get(
                `/participants/${id}/economy/pmo`,
                {
                    params: {
                        startDate,
                        endDate,
                        contractType,
                        consumerType,
                        basekWhPrice,
                        peakkWhPrice,
                        offPeakkWhPrice,
                        summerPeakkWhPrice,
                        summerOffPeakkWhPrice,
                        winterPeakkWhPrice,
                        winterOffPeakkWhPrice,
                        peakStart,
                        offPeakStart,
                        TRV: isOnTRV,
                    },
                }
            );
            return data.economy;
        } catch (err) {
            if (
                err.response?.status === 400 &&
                err.response?.data?.invalidFields?.length
            ) {
                return null;
            }
            throw err;
        }
    };

    getConsumptionByConsumerIdAndPeriod = async (
        consumerId,
        startDate,
        endDate
    ) => {
        const { data } = await client.get(
            `measurements/metrics/by-participant?participantId=${consumerId}&nature=${MetricNaturesEnum.CONSUMER_CONSUMPTION}&dateFrom=${startDate}&dateTo=${endDate}`
        );

        const selfConsumed = parseFloat(
            data?.metrics?.consumerAutoConsumption || 0
        );
        const globalConsumption =
            parseFloat(data?.metrics?.consumerSupplierConsumption || 0) +
            selfConsumed;
        return {
            selfConsumed,
            globalConsumption,
        };
    };

    getPriceConfig = async (consumerId) => {
        const { data } = await client.get(
            `/participants/${consumerId}/economy/pmo-price-config`
        );
        if (data) {
            data.isOnTRV = data.TRV;
            data.peakHourStart = data.peakStart;
            data.offPeakHourStart = data.offPeakStart;
            delete data.TRV;
            delete data.peakStart;
            delete data.offPeakStart;
        }
        return data;
    };

    getPriceConfigs = async (consumerIds) => {
        const { data } = await client.get(
            `/economy/pmo-price-configs?participantsIds=${consumerIds.join(
                ','
            )}`
        );
        return data.map((configMapping) => {
            const config = configMapping.config;
            if (config) {
                config.isOnTRV = config.TRV;
                config.peakHourStart = config.peakStart;
                config.offPeakHourStart = config.offPeakStart;
                delete config.TRV;
                delete config.peakStart;
                delete config.offPeakStart;
            }
            return {
                consumerId: configMapping.participantId,
                config,
            };
        });
    };

    getTRVPrice = async () => {
        const { data } = await client.get(`/trv-prices/last`);
        return data;
    };
}
