import { SET_COMMUNICATION_RESULT } from './constants';

const initialState = {
    result: {
        message: null,
        messageType: null,
    },
};

function communicationReducer(state = initialState, action) {
    switch (action.type) {
        case SET_COMMUNICATION_RESULT: {
            return {
                ...state,
                result: {
                    message: action.message,
                    messageType: action.messageType,
                },
            };
        }

        default:
            return state;
    }
}

export default communicationReducer;
