import { combineReducers } from 'redux';
import { metricsReducer } from './metricsReducer';
import { filterReducer } from './filterReducer';
import { cdcReducer } from './cdcReducer';
import { distributionReducer } from './distributionReducer';
import { isQueryingDataInRangeReducer } from './isQueryingDataInRangeReducer';

export default combineReducers({
    metrics: metricsReducer,
    filter: filterReducer,
    cdc: cdcReducer,
    distribution: distributionReducer,
    isQueryingDataInRange: isQueryingDataInRangeReducer,
});
