import { createSelector } from 'reselect';
import { MetricNaturesEnum } from '../../../../../../app-config';
import { selectMetrics } from './metricsSelector';

const selectConsumptionByConsumersMetricsState = createSelector(
    selectMetrics,
    (metrics) => metrics[MetricNaturesEnum.CONSUMPTION_BY_CONSUMERS]
);
export const selectConsumptionByConsumersMetricsData = createSelector(
    selectConsumptionByConsumersMetricsState,
    (metrics) => metrics.data || []
);
export const selectConsumptionByConsumersMetricsLoading = createSelector(
    selectConsumptionByConsumersMetricsState,
    (metrics) => metrics.loading
);
