import { createSelector } from 'reselect';
import { MetricNaturesEnum } from '../../../../../../app-config';
import { selectMetrics } from './metricsSelector';

const selectAutoConsumptionByConsumersMetricsState = createSelector(
    selectMetrics,
    (metrics) => metrics[MetricNaturesEnum.AUTO_CONSUMPTION_BY_CONSUMERS]
);
export const selectAutoConsumptionByConsumersMetricsData = createSelector(
    selectAutoConsumptionByConsumersMetricsState,
    (metrics) => metrics.data || []
);
export const selectAutoConsumptionByConsumersMetricsLoading = createSelector(
    selectAutoConsumptionByConsumersMetricsState,
    (metrics) => metrics.loading
);
