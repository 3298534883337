// Consumption
import { createSelector } from 'reselect';
import { colors, MetricNaturesEnum } from '../../../../../../app-config';
import { getByProducersPieChartColors, selectMetrics } from './metricsSelector';
import { selectParticipants } from '../../operationSelectors';
import { selectACType } from '../../functionalitiesSelector';

// ACC
const selectAccProductionByProducersMetrics = createSelector(
    selectMetrics,
    (metrics) => metrics[MetricNaturesEnum.ACC_PRODUCTION_BY_PRODUCERS]
);
export const selectAccProductionByProducersMetricsData = createSelector(
    selectAccProductionByProducersMetrics,
    (metrics) => metrics.data || []
);
const selectAccProductionByProducersMetricsLoading = createSelector(
    selectAccProductionByProducersMetrics,
    (metrics) => metrics.loading
);

// ACI
const selectAciProductionByProducersMetrics = createSelector(
    selectMetrics,
    (metrics) => metrics[MetricNaturesEnum.ACI_PRODUCTION_BY_PRODUCERS]
);
const selectAciProductionByProducersMetricsData = createSelector(
    selectAciProductionByProducersMetrics,
    (metrics) => metrics.data || []
);
const selectAciProductionByProducersMetricsLoading = createSelector(
    selectAciProductionByProducersMetrics,
    (metrics) => metrics.loading
);

export const selectProductionByProducersMetricsData = createSelector(
    selectACType,
    selectAccProductionByProducersMetricsData,
    selectAciProductionByProducersMetricsData,
    (acType, accData, aciData) => (acType === 'ACC' ? accData : aciData)
);

export const selectProductionByProducersMetrics = createSelector(
    selectProductionByProducersMetricsData,
    selectParticipants,
    (productionByProducersMetrics, participants) => {
        const metrics = [];
        if (participants.length) {
            productionByProducersMetrics.forEach(
                (productionByProducersMetric) => {
                    const participant = participants.find(
                        (part) =>
                            part.id.toString() ===
                            productionByProducersMetric.participantId.toString()
                    );
                    if (participant) {
                        const metric = {
                            value: productionByProducersMetric.value,
                            name: participant.name || participant.dsoId,
                        };
                        metrics.push(metric);
                    }
                }
            );
        }
        return metrics;
    }
);
export const selectProductionByProducersMetricsColors = createSelector(
    selectProductionByProducersMetrics,
    (data) => {
        return getByProducersPieChartColors(colors.PRODUCTION, data.length, 60);
    }
);

export const selectProductionByProducersMetricsLoading = createSelector(
    selectACType,
    selectAccProductionByProducersMetricsLoading,
    selectAciProductionByProducersMetricsLoading,
    (acType, accLoading, aciLoading) =>
        acType === 'ACC' ? accLoading : aciLoading
);
