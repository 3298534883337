import client from '../../libs/client';

export default class PaymentGateway {
    createParticipantPaymentDetail = async (participantId, paymentDetail) => {
        const res = await client.post(
            `/participants/${participantId}/payments/creditor`,
            paymentDetail
        );
        return res;
    };

    createParticipantMandatesDetails = async (
        participantId,
        mandatesDetails
    ) => {
        const producerIds = Object.keys(mandatesDetails);
        if (producerIds.length > 0) {
            return Promise.all(
                producerIds.map(async (producerId) => {
                    const res = await client.post(
                        `/participants/${producerId}/payments/mandates`,
                        {
                            ...mandatesDetails[producerId],
                            participantId,
                        }
                    );
                    return res;
                })
            );
        }
    };

    createMultipleMandatesForProducer = async (producerId, mandatesDetails) => {
        const { data: results } = await client.post(
            `/payments/mandates/import/by-creditor/${producerId}`,
            {
                mandates: mandatesDetails,
            }
        );

        return results;
    };

    downloadSepaFile = async (producerId, billingPeriodEnd, producerName) => {
        const { data } = await client.get(
            `/participants/${producerId}/payments/sepa?periodEnd=${billingPeriodEnd}`
        );
        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
        const href = URL.createObjectURL(blob);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        const fileName = `${producerName}_${billingPeriodEnd}_sepa.xml`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    getCreditorsDetailsByProducers = async (producersIds) => {
        const { data: creditors } = await client.get(
            `/payments/creditors/by-producers?participantsIds=${producersIds.join(
                ','
            )}`
        );
        return creditors;
    };

    getProducerMandates = async (producerId) => {
        const { data: mandates } = await client.get(
            `/payments/mandates/by-creditor/${producerId}`
        );
        return mandates;
    };
}
