import { INIT_MEASUREMENT, SET_CDC_DATA, SET_CDC_LOADING } from '../constants';

const initialState = {
    loading: false,
    data: null,
};

export function cdcReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CDC_DATA: {
            return {
                ...state,
                data: action.cdc,
            };
        }

        case SET_CDC_LOADING: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case INIT_MEASUREMENT: {
            return { ...initialState };
        }
        default:
            return state;
    }
}
