import {
    SET_OPERATION_CREDITORS_DETAILS,
    SET_PRODUCER_MANDATES,
} from './constants';
import { combineReducers } from 'redux';

function mandatesReducer(state = [], action) {
    switch (action.type) {
        case SET_PRODUCER_MANDATES: {
            return action.mandates;
        }
        default:
            return state;
    }
}

function creditorsReducer(state = [], action) {
    switch (action.type) {
        case SET_OPERATION_CREDITORS_DETAILS: {
            return action.creditors;
        }
        default:
            return state;
    }
}

export default combineReducers({
    mandates: mandatesReducer,
    creditors: creditorsReducer,
});
