import React from 'react';
import { withSnackbar } from 'notistack';
import { createStructuredSelector } from 'reselect';
import { selectNotif } from '../../../../../corelogic/redux/selectors/notifSelector';
import { connect } from 'react-redux';
import { initNotif } from '../../../../../corelogic/redux/actions';

class GlobalSnackbar extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { notif, initNotif } = this.props;
        if (prevProps.notif !== notif && notif.message) {
            this.props.enqueueSnackbar(notif.message, {
                autoHideDuration: notif.duration || 4000,
                variant: notif.variant || 'success',
            });
            initNotif();
        }
    }

    render() {
        return <div></div>;
    }
}

const mapStateToProps = createStructuredSelector({
    notif: selectNotif,
});

const mapDispatchToProps = {
    initNotif,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSnackbar(GlobalSnackbar));
