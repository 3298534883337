import { createSelector } from 'reselect';

const selectNavigation = (state) => state.navigation;

const selectAppMap = createSelector(
    selectNavigation,
    (navigation) => navigation.appMap
);

export { selectAppMap };
