import { INIT_NOTIF, SET_NOTIF } from './constants';

const initialState = {
    message: null,
    variant: null,
    duration: null,
};

function notifReducer(state = initialState, action) {
    switch (action.type) {
        case SET_NOTIF: {
            return {
                message: action?.notif?.message || null,
                variant: action?.notif?.variant || null,
                duration: action?.notif?.duration || null,
            };
        }
        case INIT_NOTIF: {
            return {
                message: null,
                variant: null,
                duration: null,
            };
        }
        default:
            return state;
    }
}

export default notifReducer;
