import {
    APPLY_CONFIG,
    INIT_CONSUMPTION_AND_ECONOMY,
    INIT_GLOBAL_FORM,
    INIT_PARTICIPANT_FORM,
    SELECT_ALL_PARTICIPANTS,
    SELECT_PARTICIPANT,
    SET_BASE_KWH_PRICE,
    SET_CONSUMER_TYPE,
    SET_CONSUMPTION_AND_ECONOMY,
    SET_CONSUMPTION_AND_ECONOMY_LOADING,
    SET_CONTRACT_TYPE,
    SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA,
    SET_END_DATE,
    SET_IS_ON_TRV,
    SET_OFF_PEAK_HOUR_START,
    SET_OFF_PEAK_KWH_PRICE,
    SET_PEAK_HOUR_START,
    SET_PEAK_KWH_PRICE,
    SET_START_DATE,
    SET_SUMMER_OFF_PEAK_KWH_PRICE,
    SET_SUMMER_PEAK_KWH_PRICE,
    SET_TRV_PRICE,
    SET_WINTER_OFF_PEAK_KWH_PRICE,
    SET_WINTER_PEAK_KWH_PRICE,
    UNSELECT_ALL_PARTICIPANTS,
    UNSELECT_PARTICIPANT,
} from './constants';
import { combineReducers } from 'redux';

const consumptionsAndEconomiesReducer = (state = [], action) => {
    switch (action.type) {
        case SET_CONSUMPTION_AND_ECONOMY:
            return [...state, action.consumptionAndEconomy];
        case INIT_CONSUMPTION_AND_ECONOMY:
            return [];
        default:
            return state;
    }
};

const loadingReducer = (state = false, action) => {
    switch (action.type) {
        case SET_CONSUMPTION_AND_ECONOMY_LOADING:
            return action.loading;
        default:
            return state;
    }
};

const participantForm = (state = {}, action) => {
    switch (action.type) {
        case SET_CONSUMER_TYPE:
            const { consumerType } = action;
            return { ...state, consumerType };
        case SET_IS_ON_TRV:
            const { isOnTRV } = action;
            return { ...state, isOnTRV };
        case SET_CONTRACT_TYPE: {
            return { ...state, contractType: action.contractType };
        }
        case SET_OFF_PEAK_HOUR_START: {
            return { ...state, offPeakHourStart: action.offPeakHourStart };
        }
        case SET_PEAK_HOUR_START: {
            return { ...state, peakHourStart: action.peakHourStart };
        }
        case SET_BASE_KWH_PRICE: {
            return { ...state, basekWhPrice: action.basekWhPrice };
        }
        case SET_PEAK_KWH_PRICE: {
            return { ...state, peakkWhPrice: action.peakkWhPrice };
        }
        case SET_OFF_PEAK_KWH_PRICE: {
            return { ...state, offPeakkWhPrice: action.offPeakkWhPrice };
        }
        case SET_WINTER_PEAK_KWH_PRICE: {
            return { ...state, winterPeakkWhPrice: action.winterPeakkWhPrice };
        }
        case SET_WINTER_OFF_PEAK_KWH_PRICE: {
            return {
                ...state,
                winterOffPeakkWhPrice: action.winterOffPeakkWhPrice,
            };
        }
        case SET_SUMMER_PEAK_KWH_PRICE: {
            return { ...state, summerPeakkWhPrice: action.summerPeakkWhPrice };
        }
        case SET_SUMMER_OFF_PEAK_KWH_PRICE: {
            return {
                ...state,
                summerOffPeakkWhPrice: action.summerOffPeakkWhPrice,
            };
        }
        case INIT_PARTICIPANT_FORM: {
            return { ...action.participantForm };
        }
        default:
            return state;
    }
};

const globalFormReducer = (state = {}, action) => {
    const { id, participants, participantForm } = action;
    switch (action.type) {
        case SET_START_DATE:
            return { ...state, startDate: action.startDate };
        case SET_END_DATE:
            return { ...state, endDate: action.endDate };
        case APPLY_CONFIG:
            const newState = { ...state };
            participants.forEach((id) => (newState[id] = participantForm));
            return newState;
        case SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA:
            return {
                ...state,
                [id]: { ...state[id], consumerType: action.consumerType },
            };
        case INIT_GLOBAL_FORM:
            return {};
        default:
            return state;
    }
};

const selectedParticipantsReducer = (state = [], action) => {
    switch (action.type) {
        case SELECT_PARTICIPANT:
            return [...state, action.id];
        case UNSELECT_PARTICIPANT:
            const newState = [...state];
            const index = newState.indexOf(action.id);
            newState.splice(index, 1);
            return newState;
        case SELECT_ALL_PARTICIPANTS:
            return [...action.ids];
        case UNSELECT_ALL_PARTICIPANTS:
            return [];
        default:
            return state;
    }
};

const trvPriceReducer = (state = null, action) => {
    switch (action.type) {
        case SET_TRV_PRICE:
            return action.trvPrice;
        default:
            return state;
    }
};

export default combineReducers({
    data: consumptionsAndEconomiesReducer,
    loading: loadingReducer,
    participantForm: participantForm,
    selectedParticipants: selectedParticipantsReducer,
    globalForm: globalFormReducer,
    trvPrice: trvPriceReducer,
});
