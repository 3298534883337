import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { setError, setCommunicationResult } from '../../redux/actions';
import { SEND_COMMUNICATION } from '../../redux/reducers/constants';

function* sendCommunication(action) {
    try {
        const { subject, body, recipients } = action;
        const communicationGateway = yield getContext('communicationGateway');
        const res = yield call(
            communicationGateway.sendCommunication,
            subject,
            body,
            recipients
        );
        let errorMessage = '';
        if (res.data.rejected.length > 0) {
            errorMessage += `Le message n'a pas pu être envoyé aux destinataires suivants car une erreur est survenue : ${res.data.rejected.join(
                ', '
            )}.`;
        }
        if (res.data.missing.length > 0) {
            errorMessage += `Le message n'a pas pu être envoyé aux destinataires suivants car ils n'ont pas d'adresse mail : ${res.data.missing.join(
                ', '
            )}.`;
        }
        if (errorMessage) {
            yield put(
                setCommunicationResult({
                    message: `${errorMessage} ${
                        res.data.accepted.length > 0
                            ? 'Les autres destinataires ont reçu votre message.'
                            : ''
                    }`,
                    messageType: 'error',
                })
            );
        } else {
            yield put(
                setCommunicationResult({
                    message: 'Votre message a bien été envoyé.',
                    messageType: 'success',
                })
            );
        }
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

export function* sendCommunicationSaga() {
    yield takeLatest(SEND_COMMUNICATION, sendCommunication);
}

const communicationSagas = [sendCommunicationSaga];

export default communicationSagas;
