import client from '../../libs/client';
import { roles } from '../../../../../app-config';

export default class AuthenticationGateway {
    constructor(error) {
        this.error = error;
    }

    setError = (error) => {
        this.error = error;
    };

    getUser = async () => {
        const { data: user } = await client.get('/session');
        if (user.role === roles.PARTICIPANT) {
            const {
                data: [{ energyType }],
            } = await client.get(`/participants/by-jwt`);
            user.energyType = energyType;
        }
        return user;
    };

    logIn = async ({ email, password }) => {
        const res = await client.post('/session/login', {
            email,
            password,
        });
        return res;
    };

    logOut = async () => {
        const res = await client.get('/session/logout');
        return res;
    };

    acceptCgu = async (cguId) => {
        const userCgu = await client.post(`users/accept-cgu/${cguId}`);
        return userCgu;
    };

    changePassword = async ({ oldPassword, newPassword, firstTimeLogin }) => {
        return client.put(`/users/update-pw-with-old`, {
            oldPassword: oldPassword,
            newPassword: newPassword,
        });
    };

    initializeUser = async () => {
        return client.put(`/users/update-authenticated-user`, {
            initialized: true,
        });
    };
}
