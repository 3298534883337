import moment from 'moment';

export function addHours(from, hours) {
    const date = moment(from);
    const tomorrow = date.clone();
    tomorrow.add(1, 'd');
    return !date.isDST() && tomorrow.isDST() && date.hour() === 0
        ? date.add(hours - 1, 'h')
        : date.isDST() && !tomorrow.isDST() && date.hour() === 0
        ? date.add(hours + 1, 'h')
        : date.add(hours, 'h');
}

function overlaps(range1, range2) {
    return (
        includes(range1, range2.start) ||
        includes(range1, range2.end) ||
        includes(range2, range1.start) ||
        includes(range2, range1.end)
    );
}

function includes(range, date) {
    const result =
        (!range.start || moment(range.start).isSameOrBefore(moment(date))) &&
        (!range.end || moment(date).isSameOrBefore(moment(range.end)));
    return result;
}

export function dateRangeOverlaps({ from, to }, activationRange) {
    const rangeStart = activationRange?.[0]?.value;
    const rangeEnd = activationRange?.[1]?.value;
    return overlaps(
        { start: from, end: to },
        { start: rangeStart, end: rangeEnd }
    );
}
