import {
    INIT_OPERATION_INTERLOCUTOR,
    SET_OPERATION_INTERLOCUTOR,
} from './constants';

const initialState = {};

function operationInterlocutorReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_OPERATION_INTERLOCUTOR: {
            return {};
        }
        case SET_OPERATION_INTERLOCUTOR: {
            return { ...action.interlocutor };
        }
        default:
            return state;
    }
}

export default operationInterlocutorReducer;
