import {
    INIT_IS_QUERYING_DATA_IN_RANGE,
    INIT_MEASUREMENT,
    SET_IS_QUERYING_DATA_IN_RANGE,
} from '../constants';

const initialState = null;

export function isQueryingDataInRangeReducer(state = initialState, action) {
    switch (action.type) {
        case SET_IS_QUERYING_DATA_IN_RANGE: {
            return action.isQueryingDataInRange;
        }

        case INIT_IS_QUERYING_DATA_IN_RANGE:
        case INIT_MEASUREMENT: {
            return initialState;
        }
        default:
            return state;
    }
}
